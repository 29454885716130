import { useCallback, useEffect, useMemo, useState } from 'react';
import { api } from '../api/api';
import { useNotificationsContext } from '../contexts/NotificationsContext';

export const useApiCallback = (cb, deps = []) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const notifications = useNotificationsContext();
  const cbExists = !!cb;

  const execute = useCallback(
    async args => {
      if (!cbExists) {
        return;
      }
      setData(null);
      setError(null);
      setLoading(true);
      try {
        const response = await cb(api, args);
        setData(response.data);
        return response;
      } catch (e) {
        if (e.response) {
          setError(e.response.data); // { message, code }
          if (e.response.data.message) notifications.show(e.response.data.message, 'error');
        } else {
          setError(new Error('Something went wrong'));
        }
        return undefined;
      } finally {
        setLoading(false);
      }
    },
    [cbExists, ...deps]
  );

  return useMemo(() => ({ data, error, loading, execute }), [data, error, loading, execute]);
};

export const useApi = (cb, deps = []) => {
  const apiCb = useApiCallback(cb, deps);

  useEffect(() => {
    apiCb.execute();
  }, [...deps]);

  return apiCb;
};
