import React from 'react';

import css from './TopBarSimpleClose.module.css';

import BackArrowIcon from '../../assets/icons/arrows/back.png';

function TopBarSimpleClose({ onCloseClick, title }) {
  return (
    <div className={css.root}>
      <button onClick={onCloseClick} type="button" className={css.closeBtn}>
        <img className={css.closeIcon} src={BackArrowIcon} alt="Close" />
      </button>
      <h1 className={css.title}>{title}</h1>
    </div>
  );
}

export default TopBarSimpleClose;
