import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Lexend, sans-serif',
    },
  },
  palette: {
    primary: {
      main: '#235641',
      light: '#47ae83',
      dark: '#29654c',
    },
    text: {
      primary: '#212529',
      secondary: '#6c757d',
    },
    error: {
      main: '#E02424',
    },
  },
});
