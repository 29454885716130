export const ltTranslations = {
  'banner.secure_payments': 'Saugūs mokėjimai',
  'banner.buyer_protection': 'Pirkėjo apsauga',
  'banner.delivery': 'Greitas ir integruotas pristatymas',

  'landing_page.sell_button': 'Parduok',
  'landing_page.buy_button': 'Rask naują dviratį',
  'landing_page.section_hero.sub_title': 'Įkelk nemokamai',
  'landing_page.section_hero.title': 'Turi nenaudojamą dviratį?',
  'landing_page.section_hero.description_1': 'Platforma sukurta sporto entuziastams.',
  'landing_page.section_hero.description_2': 'Pasiek tūkstančius pirkėjų nemokamai.',
  'landing_page.schema_description':
    'Pirkite ir parduokite naudotus plento, gravel ir kalnų dviračius didžiausioje naudotų dviračių internetinėje platformoje Gearro. Su pirkėjų apsauga, greitu pristatymu ir saugiais mokėjimais.',
  'Landing_page.schema_title': 'Atraskite plento, Gravel ar MTB dviračius | Gearro',
  'landing_page.categories_label': 'Kategorijos',
  'landing_page.most_recent_label': 'Naujausi dviračiai',
  'landing_page.most_popular_label': 'Rekomenduojami',
  'landing_page.popular_brands': 'Populiariausi gamintojai',
  'landing_page.view_more': 'Žiūrėti daugiau',
  'landing_page.sell_bike_ad.title': 'Parduok savo dviratį',
  'landing_page.sell_bike_ad.description': 'Įkelk dviratį į platformą ir rask pirkėją per kelias dienas.',
  'landing_page.sell_bike_ad.button': 'Parduoti dviratį',

  'locales.english': 'EN',
  'locales.lithuanian': 'LT',
  'locales.polish': 'PL',

  'months.january': 'Sausio',
  'months.february': 'Vasario',
  'months.march': 'Kovo',
  'months.april': 'Balandžio',
  'months.may': 'Gegužės',
  'months.june': 'Birželio',
  'months.july': 'Liepos',
  'months.august': 'Rugpjūčio',
  'months.september': 'Rugsėjo',
  'months.october': 'Spalio',
  'months.november': 'Lapkričio',
  'months.december': 'Gruodžio',

  'landing_page.section_info.label': 'Kaip veikia Gearro?',

  'landing_page.section_info.sell': 'Parduok',
  'landing_page.section_info.sell.question_0': 'Papasakok apie parduodamą dviratį',
  'landing_page.section_info.sell.answer_0':
    'Pateik dviračio specifikacijas kaip dydį, medžiagą, spalvą ir kitas detales.',
  'landing_page.section_info.sell.question_1': 'Atsakyk į užklausas',
  'landing_page.section_info.sell.answer_1':
    'Bendrauk su potencialiais pirkėjais per susirašinėjimo sistemą, atsakyk į klausimus ar suplanuok testinį važiavimą.',
  'landing_page.section_info.sell.question_2': 'Patvirtink apmokėjimą',
  'landing_page.section_info.sell.answer_2':
    'Susitik su pirkėju ir gauk pinigus grynaisiais, arba sutark dėl pavedimo internetu.',

  'landing_page.section_info.buy': 'Pirk',
  'landing_page.section_info.buy.question_0': 'Išsirink mėgstamą dviratį',
  'landing_page.section_info.buy.answer_0': 'Ieškok dviračio pagal dydį, tipą, ar kitas dviračio savybes.',
  'landing_page.section_info.buy.question_1': 'Sužinok daugiau apie dviratį',
  'landing_page.section_info.buy.answer_1': 'Susitik su pardavėju bandomajam važiavimui, arba suplanuok siuntimą.',
  'landing_page.section_info.buy.question_2': 'Patvirtink apmokėjimo būdą',
  'landing_page.section_info.buy.answer_2':
    'Susitik su pardavėju ir mokėk grynaisiais, arba sutarkite dėl pinigų pavedimo.',

  'landing_page.section_info.sell.title_0': '1. Įkelk nemokamai',
  'landing_page.section_info.sell.description_0':
    'Įkelk dviratį nemokamai. Pridėk nuotraukas, aprašymą ir nustatyk kainą.',
  'landing_page.section_info.sell.title_1': '2. Parduok ir išsiųsk',
  'landing_page.section_info.sell.description_1':
    'Parduotas! Supakuok dviratį, atspausdink siuntimo etiketę ir užsisakyk kurjerį per 5 dienas.',
  'landing_page.section_info.sell.title_2': '3. Gauk pinigus',
  'landing_page.section_info.sell.description_2':
    'Nėra pardavimo mokesčių, tad viską, ką uždirbi, ir laikai sau. Mokėjimą gausi, kai pirkėjas patvirtins, kad viskas tvarkinga.',
  'landing_page.section_info.buy.title_0': '1. Rask dviratį',
  'landing_page.section_info.buy.description_0':
    'Ieškok tūkstančių dviračių, ieškok pagal gamintoją ar kitas specifikacijas ir atrask savo idealų variantą.',
  'landing_page.section_info.buy.title_1': '2. Pirkti',
  'landing_page.section_info.buy.description_1':
    'Paklausk pardavėjo klausimų, tada pirkti vienu paspaudimu. Mokėk saugiai per Stripe, banko kortele ar Apple Pay.',
  'landing_page.section_info.buy.title_2': '3. Gauk',
  'landing_page.section_info.buy.description_2':
    'Pristatymo data bus nurodyta užsakymo metu, ir jis bus pristatytas tiesiai į tavo duris.',

  'landing_page.section_hero.slide_1.title': 'Parduok dviratį ir gauk pinigus',
  'landing_page.section_hero.slide_1.content': 'Įkelk nemokamai per mažiau nei minutę.',
  'landing_page.section_hero.slide_1.button': 'Parduok dabar',
  'landing_page.section_hero.slide_2.title': 'Greiti pardavimai, sklandūs važiavimai',
  'landing_page.section_hero.slide_2.content': 'Paprastas, saugus pardavimo procesas.',
  'landing_page.section_hero.slide_2.button': 'Parduok dabar',
  'landing_page.section_hero.slide_3.title': 'Gauk norimą prekę už mažiau',
  'landing_page.section_hero.slide_3.content': 'Sutaupyk vidutiniškai 30% perkant naudotą sporto įrangą.',
  'landing_page.section_hero.slide_3.button': 'Peržiūrėti visus',

  'config.labels.condition': 'Būklė',
  'config.labels.shipping': 'Siuntimas',
  'config.labels.receiptAvailable': 'Pirkimo dokumentai',
  'config.labels.frameColor': 'Spalva',
  'config.labels.frameMaterial': 'Rėmas',
  'config.labels.wheelSize': 'Ratų dydis',
  'config.labels.frameSize': 'Rėmo dydis',
  'config.labels.year': 'Metai',
  'config.labels.brand': 'Gamintojas',
  'config.labels.category': 'Dviračio kategorija',
  'config.labels.price': 'Kaina',
  'config.labels.keyword': 'Raktinis žodis',
  'config.labels.model': 'Modelis',
  'config.labels.country': 'Šalis',

  'categories.city': 'Miesto',
  'categories.road': 'Plento',
  'categories.mountain': 'Kalnų',
  'categories.gravel': 'Gravel',
  'categories.bmx_dirt': 'Bmx ir Dirt',
  'categories.folding': 'Sulankstomas',
  'categories.electric': 'Elektrinis',
  'categories.enduro': 'Enduro',
  'categories.downhill': 'Downhill',
  'categories.other': 'Kita',

  'year.placeholder': 'Pasirink metus',
  'frame_size.placeholder': 'Pasirink rėmo dydį',
  'brand.placeholder': 'Pasirink gamintoją',
  'country.placeholder': 'Pasirink šalį',

  'country.LT': 'Lietuva',
  'country.LV': 'Latvija',
  'country.EE': 'Estija',
  'country.PL': 'Lenkija',
  'country.all': 'Visos',

  'frame_size.xxl': 'XXL',
  'frame_size.xl': 'XL',
  'frame_size.l': 'L',
  'frame_size.m': 'M',
  'frame_size.s': 'S',
  'frame_size.xs': 'XS',
  'frame_size.xxs': 'XSS',

  'front_travel.60': '60',
  'front_travel.70': '70',
  'front_travel.80': '80',
  'front_travel.90': '90',
  'front_travel.100': '100',
  'front_travel.110': '110',
  'front_travel.120': '120',
  'front_travel.130': '130',
  'front_travel.140': '140',
  'front_travel.150': '150',
  'front_travel.160': '160',
  'front_travel.170': '170',
  'front_travel.180': '180',
  'front_travel.190': '190',
  'front_travel.200': '200',
  'front_travel.210': '210',
  'front_travel.220': '220',
  'front_travel.230': '230',

  'rear_travel.60': '60',
  'rear_travel.70': '70',
  'rear_travel.80': '80',
  'rear_travel.90': '90',
  'rear_travel.100': '100',
  'rear_travel.110': '110',
  'rear_travel.120': '120',
  'rear_travel.130': '130',
  'rear_travel.140': '140',
  'rear_travel.150': '150',
  'rear_travel.160': '160',
  'rear_travel.170': '170',
  'rear_travel.180': '180',
  'rear_travel.190': '190',
  'rear_travel.200': '200',
  'rear_travel.210': '210',
  'rear_travel.220': '220',
  'rear_travel.230': '230',

  'sub_categories.road': 'Plento',
  'sub_categories.track': 'Treko',
  'sub_categories.aearo_triathlon_tt': 'Aearo/Triathlon/TT',
  'sub_categories.single_gear': 'Single gear',
  'sub_categories.xc_cross_country': 'XC / Cross Country',
  'sub_categories.trail': 'Trail',
  'sub_categories.enduro': 'Enduro',
  'sub_categories.downhill': 'Downhill',
  'sub_categories.bmx': 'Bmx',
  'sub_categories.dirt_street': 'Dirt/Street Bikes',
  'sub_categories.electric_city': 'Miesto',
  'sub_categories.electric_mountain': 'Kalnų',
  'sub_categories.electric_road': 'Plento',
  'sub_categories.electric_other': 'Kita',
  'sub_categories.cargo': 'Cargo',
  'sub_categories.tricycle': 'Triratis',
  'sub_categories.tandem': 'Tandem',
  'sub_categories.balance': 'Balansinis',
  'sub_categories.unicycle': 'Vienaratis',

  'shipping.no_shipping': 'Atsiėmimas susitikus',
  'shipping.domestic_shipping': 'Siuntimas šalies viduje',
  'shipping.international_shipping': 'Tarptautinis',

  'frame_material.aluminum': 'Aliuminis',
  'frame_material.carbon': 'Karboninis',
  'frame_material.steel': 'Plieno',
  'frame_material.other': 'Kita',

  'frame_color.pink': 'Rožinis',
  'frame_color.green': 'Žalias',
  'frame_color.orange': 'Oranžinis',
  'frame_color.blue': 'Mėlynas',
  'frame_color.red': 'Raudonas',
  'frame_color.white': 'Baltas',
  'frame_color.yellow': 'Geltonas',
  'frame_color.black': 'Juodas',
  'frame_color.grey': 'Pilkas',
  'frame_color.other': 'Kita',

  'wheel_size.16': '16″',
  'wheel_size.20': '20″',
  'wheel_size.28': '28″ (700c)',
  'wheel_size.26': '26”',
  'wheel_size.27.5': '27.5” (650b)',
  'wheel_size.29': '29”',
  'wheel_size.650B': '650B',
  'wheel_size.700C': '28” (700c)',
  'wheel_size.other': 'Kita',

  'receipt.yes': 'Taip',
  'receipt.no': 'Ne',

  'condition.bad': 'Dviratis dalims',
  'condition.partly_bad': 'Reikia remonto',
  'condition.good': 'Geras',
  'condition.perfect': 'Puikios būklės',
  'condition.brand_new': 'Naujas',

  'condition.bad.label': '✔ Netinkamas važiuoti\n✔ Reikalinga apžiūra ir remontas\n✔ Reikalingas dalių keitimas',
  'condition.partly_bad.label': '✔ Turi šiek tiek įbrėžimų\n✔ Reikalingas servisas\n✔ Reikia pakeisti kai kurias dalis',
  'condition.good.label':
    '✔ Turi šiek tiek įbrėžimų\n✔ Gali reikėti profilaktinio dviračio aptarnavimo\n✔ Dalių keitimas nereikalingas',
  'condition.perfect.label': '✔ Turi nežymių įbrėžimų\n✔ Techniškai tvarkingas\n✔ Nėra defektų',
  'condition.brand_new.label': '✔ Nenaudotas dviratis',

  'top_bar.search_form.placeholder': 'Paieška',
  'top_bar.generic_error_message': 'Kažkas nutiko. Bandyk dar kartą.',
  'top_bar.logo_icon_label': 'Eiti į pradinį puslapį',
  'top_bar.menu_icon_label': 'Atidaryti meniu',

  'top_bar.log_in': 'Prisijungti',
  'top_bar.log_out': 'Atsijungti',
  'top_bar.sell': 'Parduok',
  'top_bar.inbox_label': 'Žinutės',
  'top_bar.listings_label': 'Tavo skelbimai',
  'top_bar.profile_settings_label': 'Profilio nustatymai',
  'top_bar.account_settings_label': 'Nustatymai',
  'top_bar.profile': 'Profilis',
  'top_bar.purchases': 'Pirkimai',
  'top_bar.sales': 'Pardavimai',

  'top_bar.mobile_menu.buy': 'Pirk',
  'top_bar.mobile_menu.about_page': 'Apie Gearro',
  'top_bar.mobile_menu.how_it_works_page': 'How it works',
  'top_bar.mobile_menu.show_profile': 'Eiti į profilį',
  'top_bar.mobile_menu.account_label': 'Paskyra',
  'top_bar.mobile_menu.services_label': 'Paslaugos',
  'top_bar.mobile_menu.favorite_listings': 'Pamėgti skelbimai',
  'top_bar.mobile_menu.explore': 'Ieškoti',
  'top_bar.mobile_menu.all_categories': 'Visos kategorijos',
  'top_bar.mobile_menu.terms_of_service': 'Paslaugų teikimo sąlygos',
  'top_bar.mobile_menu.privacy_policy': 'Privatumo politika',

  'top_bar.buy': 'Pirk',

  'top_bar.desktop.logo_label': 'Gearro',

  'authentication_page.or': 'arba',
  'authentication_page.facebook_login': 'Tęsti su Facebook',
  'authentication_page.google_login': 'Tęsti su Google',
  'authentication_page.email_login': 'Tęsti su el. paštu',
  'authentication_page.terms_and_conditions_accept_label':
    "Užsiregistruodamas patvirtinu, jog sutinku su Gearro's {termsLink}, ir {privacyPolicyLink}",
  'authentication_page.terms_and_conditions_label': 'Bendrosiomis Taisyklėmis',
  'authentication_page.privacy_policy_label': 'Privatumo Politika',

  'log_in.email_label': 'El. paštas',
  'log_in.email_placeholder': 'El. paštas',
  'log_in.email_required': 'Įvesk el. paštą',
  'log_in.email_invalid': 'El. paštas neteisingas',
  'log_in.password_label': 'Slaptažodis',
  'log_in.password_placeholder': 'Slaptažodis',
  'log_in.password_required': 'Įvesk slaptažodį',
  'log_in.forgot_password': 'Pamiršai slaptažodį?',
  'log_in.log_in_label': 'Prisijunk',
  'log_in.sign_up_prompt': 'Neturi paskyros? ',
  'log_in.sign_up_label': 'Prisiregistruok',
  'log_in.failed': 'El. paštas ir slaptažodis neegzistuoja. Patikrink ir bandyk dar kartą',

  'sign_up.email_label': 'El. paštas',
  'sign_up.email_placeholder': 'El. paštas',
  'sign_up.email_required': 'Įvesk el. paštą',
  'sign_up.email_invalid': 'El. paštas neteisingas',
  'sign_up.password_label': 'Slaptažodis',
  'sign_up.password_placeholder': 'Slaptažodis',
  'sign_up.password_required': 'Įvesk slaptažodį',
  'sign_up.password_too_short': 'Slaptažodis turi būti bent {minLength} simbolių',
  'sign_up.password_too_long': 'Slaptažodis negali būti ilgesnis nei {maxLength} simbolių',
  'sign_up.first_name_label': 'Vardas',
  'sign_up.first_name_placeholder': 'Vardas',
  'sign_up.first_name_required': 'Įvesk vardą',
  'sign_up.last_name_label': 'Pavardė',
  'sign_up.last_name_placeholder': 'Pavardė',
  'sign_up.last_name_required': 'Įvesk pavardę',
  'sign_up.user_name_label': 'Vartotojo vardas',
  'sign_up.user_name_placeholder': 'Vartotojo vardas',
  'sign_up.user_name_required': 'Turi pasirinkti vartotojo vardą',
  'sign_up.sign_up_label': 'Prisiregistruoti',
  'sign_up.business.sign_up_label': 'Prisiregistruoti kaip įmonei',
  'sign_up.log_in_prompt': 'Jau turi paskyrą? ',
  'sign_up.log_in_label': 'Prisijunk',

  'confirm_sign_up.email_label': 'El. paštas',
  'confirm_sign_up.email_placeholder': 'El. paštas',
  'confirm_sign_up.email_required': 'Įvesk el. paštą',
  'confirm_sign_up.email_invalid': 'El. paštas neteisingas',
  'confirm_sign_up.first_name_label': 'Vardas',
  'confirm_sign_up.first_name_placeholder': 'Pavardė',
  'confirm_sign_up.first_name_required': 'Įvesk vardą',
  'confirm_sign_up.last_name_label': 'Pavardė',
  'confirm_sign_up.last_name_placeholder': 'Pavardė',
  'confirm_sign_up.last_name_required': 'Įvesk pavardę',
  'confirm_sign_up.user_name_label': 'Vartotojo vardas',
  'confirm_sign_up.user_name_placeholder': 'Vartotojo vardas',
  'confirm_sign_up.user_name_required': 'Turi pasirinkti vartotojo vardą',
  'confirm_sign_up.confirm_and_continue_label': 'Sutinku, tęsti',

  'terms_of_service_page.schema_title': 'Naudojimosi taisyklės | {siteTitle}',

  'avatar.banned_user_label': 'Užblokuotas narys',
  'avatar.deleted_user_label': 'Ištrintas narys',

  'footer.copyright': '© Gearro',
  'footer.product': 'Produktas',
  'footer.buy_bike': 'Pirkti dviratį',
  'footer.sell_bike': 'Parduoti dviratį',
  'footer.company': 'Įmonė',
  'footer.about_us': 'Apie mus',
  'footer.support': 'Pagalba',
  'footer.faq': 'DUK',
  'footer.contact_us': 'info@gearro.com',
  'footer.business_join': 'Prisijunk kaip komercinis pardavėjas',
  'footer.legal': 'Teisinė informacija',
  'footer.terms_conditions': 'Paslaugų teikimo sąlygos',
  'footer.privacy_policy': 'Privatumo politika',
  'footer.instagram_link': 'Eiti į Instagram puslapį',
  'footer.facebook_link': 'Eiti į Facebook puslapį',
  'footer.linkedin_link': 'Eiti į LinkedIn puslapį',
  'footer.content_rights': '© Gearro. Visos teisės saugomos.',

  'modal.close': 'Uždaryti langą',

  'filter_form.cancel': 'Atšaukti',
  'filter_form.clear': 'Išvalyti',
  'filter_form.submit': 'Pritaikyti',

  'actions.cancel': 'Atšaukti',
  'actions.clear': 'Išvalyti',
  'actions.submit': 'Pritaikyti',
  'actions.publish': 'Paskelbti',
  'actions.continue': 'Tęsti',
  'actions.back': 'Atgal',
  'actions.add': 'Pridėti',
  'actions.edit': 'Redaguoti',
  'actions.pay': 'Mokėti',
  'actions.got_it': 'Supratau',

  'order_actions.accept_order': 'Priimti',
  'order_actions.reject_order': 'Atmesti',
  'order_actions.get_shipping_label': 'Gauti siuntimo etiketę',
  'order_actions.download_shipping_label': 'Atsisiųsti siuntimo etiketę',
  'order_actions.request_courier': 'Užsisakyti kurjerį',
  'order_actions.track_order': 'Sekti užsakymą',
  'order_actions.mark_order_as_shipped': 'Pažymėti kaip išsiųstą',
  'order_actions.mark_order_as_delivered': 'Pažymėti kaip pristatytą',
  'order_actions.packing_instructions': 'Pakuotės instrukcijos',
  'order_actions.issue_notice': 'Turiu problemą',
  'order_actions.mark_as_ok': 'Viskas gerai',

  'search_page.price_filter.cancel': 'Atšaukti',
  'search_page.price_filter.clear': 'Išvalyti',
  'search_page.price_filter.plain_label': 'Kaina',
  'search_page.price_filter.label': 'Kaina:',
  'search_page.price_filter.submit': 'Pritaikyti',
  'search_page.price_filter.to': 'iki',
  'search_page.header.foundResults': 'Rasta - {count}',
  'search_page.header.loadingResults': 'Ieškoma rezultatų',
  'search_page.sortBy': 'Rikiuoti pagal',
  'search_page.filter_plain.selected': '• {count}',
  'search_page.multiple_filter.selected': '{labelText} • {count}',
  'search_page.reset_filters': 'Nuimti filtrus',
  'search_page.no_results': 'Nepavyko rasti rezulatų pagal pateiktą užklausą',
  'search_page.empty_state.message': 'Dėja, nepavyko rasti rezultatų pagal pateiktą užklausą',
  'search_page.price_filter.selected': '{minPrice} - {maxPrice}',
  'search_page.mobile_filters.label': 'Filtrai',
  'search_page.mobile_filters.reset': 'Atsatyti',
  'search_page.range_filter.label.selected': '{minValue} - {maxValue}',

  'search_page.mobile.show_listings': 'Rodyti',

  'profile_page.label': 'Profilis',
  'profile_page.listing.view': 'Peržiūrėti',
  'profile_page.edit_profile': 'Keisti profilio detales',
  'profile_page.joined': 'Prisijungė {createdAt}',
  'profile_page.bikes.seller': 'Tavo dviračiai',
  'profile_page.bikes.buyer': 'Parduodamos prekės',
  'profile_page.data_load_failed': 'O ne, kažkas nutiko. Pabandyk dar kartą',
  'profile_page.bio_label': 'Apie',
  'profile_page.edit_listing': 'Keisti',
  'profile_page.delete_listing': 'Ištrinti',
  'profile_page.delete_modal.title': 'Ar tu tikras, kad nori ištrinti dviratį?',
  'profile_page.delete_modal.subtitle': 'Kaip pardavei prekę?',
  'profile_page.delete_modal.reason.gearro': 'Pirkėją radau Gearro platformoje',
  'profile_page.delete_modal.reason.outside': 'Pirkėją radau kitur',
  'profile_page.delete_modal.reason.other': 'Kita',
  'profile_page.delete_modal.delete_action': 'Ištrinti',
  'profile_page.delete_modal.cancel_action': 'Atšaukti',
  'profile_page.no_listings.owner_label': 'Iškeisk seną dviratį į pinigus',

  'purchases_page.label': 'Pirkimai',
  'purchases_page.schema_title': 'Pirkimai | {siteTitle}',
  'purchases_page.placeholder': 'Čia bus rodomi jūsų pirkimai.',
  'purchases_page.error_loading_purchases': 'Nepavyko įkelti pirkimų, bandykite dar kartą vėliau.',
  'purchases_page.action_button': 'Ieškoti dviračių',

  'sales_page.label': 'Pardavimai',
  'sales_page.schema_title': 'Pardavimai | {siteTitle}',
  'sales_page.placeholder': 'Čia bus rodomi jūsų pardavimai.',
  'sales_page.error_loading_sales': 'Nepavyko įkelti pardavimų, bandykite dar kartą vėliau.',
  'sales_page.action_button': 'Parduok dabar',

  'listing_page.sold_by': '{name}',
  'listing_page.loading': 'Kraunama',
  'listing_page.order_panel': 'Įkėlė {name}',
  'listing_page.buy_now': 'Pirkti',
  'listing_page.contact_seller': 'Susisiek',
  'listing_page.description.about_bike_label': 'Apie dviratį',
  'listing_page.details.label': 'Išsami informacija',
  'listing_page.image_gallery.view_photos': 'Peržiūrėk nuotraukas ({count})',
  'listing_page.image_gallery.image_alt_text': 'Nuotrauka {index}/{count}',
  'listing_page.image_gallery.image_thumb_alt_text': 'Nuotrauka {index}/{count}',
  'listing_page.basic_information.label': 'Pagrindinė informacija',
  'listing_page.location.label': 'Lokacija',
  'listing_page.seller_details.label': 'Papildoma informacija iš pardavėjo',
  'listing_page.offered_by.label': 'Pardavėjas',
  'listing_page.add_to_favorites': 'Pridėti prie pamėgtų',
  'listing_page.remove_from_favorites': 'Pašalinti iš pamėgtų',
  'listing_page.offered_by.phone_number': 'Telefono numeris',

  'listing_page.shopping.label': 'Pirkite Gearro!',
  'listing_page.shopping.buy_sell.title': 'Pirkite ir parduokite su dviratininkais visame pasaulyje.',
  'listing_page.shopping.buy_sell.description':
    'Prisijunkite prie Gearro, vienoje vietoje, kur galite pirkti ir parduoti aukštos kokybės įrangą iki 70% pigiau, siūlomą kitiems dviratininkams.',
  'listing_page.shopping.protection.title': 'Apsaugokite savo pirkimą su mūsų pirkėjo garantija.',
  'listing_page.shopping.protection.description':
    'Jei jūsų užsakymas nesutampa su skelbimo aprašymu, susisiekite per 48 valandas. Praneškite mums apie problemą, ir mes peržiūrėsime jūsų prašymą. Jei jis yra teisingas, greitai grąžinsime jūsų pirkimą.',
  'listing_page.shopping.shipping.title': 'Greitas siuntimas ir sekimas.',
  'listing_page.shopping.shipping.description':
    'Dauguma užsakymų siunčiami per DPD Express (1-3 darbo dienos po pardavėjo siuntimo). Pardavėjai gauna išankstines etiketes, o jūs gaunate sekimą iki pristatymo.',
  'listing_page.shopping.save.title': 'Sutaupykite pinigų. Saugokime planetą!',
  'listing_page.shopping.save.description':
    'Taupydami kokybiškai naudotą įrangą išlaikote daugiau įrangos lauke ir padedate aplinkai.',

  'listing_order_page.schema_title': 'Pirkti {title} | {siteTitle}',
  'listing_order_page.summary': 'Apžvalga',
  'listing_order_page.shipping': 'Siuntimas',
  'listing_order_page.payment': 'Mokėjimas',
  'listing_order_page.listing_summary.info_section_message':
    'Pirkite su pasitikėjimu su Gearro pirkėjo garantija. Jūsų užsakymas bus grąžintas, jei jis neatitiks aprašymo.',
  'listing_order_page.listing_summary.your_purchase': 'Jūsų pirkinys',
  'listing_order_page.listing_summary.size': 'Dydis',
  'listing_order_page.listing_summary.price_details': 'Kainos informacija',
  'listing_order_page.listing_summary.bike_price': 'Dviračio kaina',
  'listing_order_page.listing_summary.purchase_protection': 'Pirkėjo garantija',
  'listing_order_page.listing_summary.shipping': 'Siuntimas',
  'listing_order_page.listing_summary.total': 'Viso ({currency})',
  'listing_order_page.shipping.delivery_provider': 'DPD Express siuntimas',
  'listing_order_page.shipping.delivery_estimate': 'Numatomas pristatymas: Vas 07 - Vas 11',
  'listing_order_page.shipping.delivery_info': 'Pristatymo informacija',
  'listing_order_page.shipping.add_delivery_info': 'Pridėti pristatymo informaciją',
  'listing_order_page.shipping.save_address': 'Išsaugoti adresą',
  'listing_order_page.shipping.save_address_error': 'Nepavyko išsaugoti adreso, bandykite dar kartą vėliau.',
  'listing_order_page.shipping.missing_address_error':
    'Jūsų pristatymo informacija yra būtina norint užbaigti užsakymą.',
  'listing_order_page.shipping.delivery_info_disclaimer':
    'Jūsų kontaktiniai duomenys gali būti bendrinami su siuntimo tiekėju norint užbaigti siuntimą.',
  'listing_order_page.delivery_info.first_name': 'Vardas',
  'listing_order_page.delivery_info.first_name_placeholder': 'Vardas',
  'listing_order_page.delivery_info.first_name_required': 'Įveskite vardą',
  'listing_order_page.delivery_info.last_name': 'Pavardė',
  'listing_order_page.delivery_info.last_name_placeholder': 'Pavardė',
  'listing_order_page.delivery_info.last_name_required': 'Įveskite pavardę',
  'listing_order_page.delivery_info.country': 'Šalis',
  'listing_order_page.delivery_info.country_placeholder': 'Šalis',
  'listing_order_page.delivery_info.address_line_1': 'Adresas 1',
  'listing_order_page.delivery_info.address_line_1_placeholder': 'Adresas 1',
  'listing_order_page.delivery_info.address_line_1_required': 'Įveskite adresą 1',
  'listing_order_page.delivery_info.address_line_2': 'Adresas 2',
  'listing_order_page.delivery_info.address_line_2_placeholder': 'Adresas 2',
  'listing_order_page.delivery_info.postal_code': 'Pašto kodas',
  'listing_order_page.delivery_info.postal_code_placeholder': 'Pašto kodas',
  'listing_order_page.delivery_info.postal_code_required': 'Įveskite pašto kodą',
  'listing_order_page.delivery_info.city': 'Miestas',
  'listing_order_page.delivery_info.city_placeholder': 'Miestas',
  'listing_order_page.delivery_info.city_required': 'Įveskite miestą',
  'listing_order_page.delivery_info.phone_number': 'Telefono numeris',
  'listing_order_page.delivery_info.phone_number_placeholder': 'Telefono numeris',
  'listing_order_page.delivery_info.phone_number_required': 'Įveskite telefono numerį',
  'listing_order_page.payment.or_card': 'Arba mokėkite kortele',
  'listing_order_page.payment.mobile_pay_inactive': 'Mobilus mokėjimas nepasiekiamas',

  'listing_order_processing_page.schema_title': 'Užsakymo apdorojimas | {siteTitle}',
  'listing_order_processing_page.title': 'Pirkimai',
  'listing_order_processing_page.processing': 'Apdorojamas užsakymas, palaukite...',
  'listing_order_processing_page.error':
    'Užsakymo apdorojimas nepavyko, prašome patikrinti užsakymo būklę savo profilyje arba susisiekite su pagalba.',

  'listing_order_details_page.schema_title': 'Užsakymo informacija | {siteTitle}',
  'listing_order_details_page.title': 'Pirkimai',
  'listing_order_details_page.order_placed': 'Užsakymas pateiktas',
  'listing_order_details_page.whats_next': 'Kas toliau?',
  'listing_order_details_page.stage_1.title': 'Užsakymo užklausa',
  'listing_order_details_page.stage_1.description':
    'Laukiama pardavėjo patvirtinimo. Jei patvirtinimo nebus gauta per 24 valandas, užsakymas bus automatiškai atšauktas.',
  'listing_order_details_page.stage_2.title': 'Pakuotė ir siuntimas',
  'listing_order_details_page.stage_2.description':
    'Pardavėjui suteikiamas 5 dienų laikotarpis, kad jis supakuotų ir išsiųstų jūsų užsakymą. Kai jis bus išsiųstas, gausite siuntimo sekimą.',
  'listing_order_details_page.stage_3.title': 'Užsakymo patvirtinimas',
  'listing_order_details_page.stage_3.description':
    'Jums suteikiamas 42 valandų laikotarpis po to, kai prekė bus pristatyta, kad patikrintumėte ir patvirtintumėte, ar ji atitinka aprašymą.',

  'listing_order_info_page.schema_title': 'Užsakymo informacija | {siteTitle}',

  'listing_order_status_page.schema_title': 'Užsakymo būklė | {siteTitle}',
  'listing_order_status_page.title': 'Užsakymo būklė',
  'listing_order_status_page.message_seller': 'Žinutė pardavėjui',
  'listing_order_status_page.message_buyer': 'Žinutė pirkėjui',
  'listing_order_status_page.order_status': 'Užsakymo būklė',
  'listing_order_status_page.delivery_address': 'Pristatymo adresas',
  'listing_order_status_page.offered_by': 'Pasiūlė',
  'listing_order_status_page.bought_by': 'Perka',
  'listing_order_status_page.payment_info': 'Mokėjimo informacija',
  'listing_order_status_page.price_details': 'Kainos informacija',
  'listing_order_status_page.bike_price': 'Dviračio kaina',
  'listing_order_status_page.purchase_protection': 'Pirkėjo garantija',
  'listing_order_status_page.shipping': 'Siuntimas',
  'listing_order_status_page.total': 'Viso ({currency})',
  'listing_order_status_page.return_policy': 'Grąžinimo politika',
  'listing_order_status_page.return_policy_info': `
    Jei jūsų užsakymas nesutampa su skelbimo aprašymu, susisiekite per 48 valandas. Praneškite mums apie problemą, ir mes peržiūrėsime jūsų prašymą. Jei jis yra teisingas, greitai grąžinsime jūsų pirkimą.
    `,
  'listing_order_status_page.get_help': 'Gauti pagalbą',
  'listing_order_status_page.get_help_info':
    'Jei jums reikia pagalbos, prašome susisiekti su mumis adresu info@gearro.com',
  'listing_order_status_page.reject_order_modal.title': 'Atmesti užsakymo užklausą?',
  'listing_order_status_page.reject_order_modal.description': 'Šio veiksmo atšaukti negalėsite.',
  'listing_order_status_page.reject_order_modal.reject_button': 'Taip, atmesti',
  'listing_order_status_page.reject_order_modal.cancel_button': 'Atšaukti',
  'listing_order_status_page.error': 'Nepavyko įkelti užsakymo būklės, prašome patikrinti URL arba bandyti vėliau.',
  'listing_order_status_page.mark_as_ok_modal.title': 'Patvirtink ar viskas gerai ir užbaik užsakymą?',
  'listing_order_status_page.mark_as_ok_modal.description':
    'Patvirtinus, jūsų mokėjimas bus išsiųstas pardavėjui ir jūs nebegalėsite pranešti apie problemas.',
  'listing_order_status_page.mark_as_ok_modal.confirm_button': 'Taip, viskas gerai',
  'listing_order_status_page.mark_as_ok_modal.cancel_button': 'Atšaukti',

  'listing_order_seller_address_page.schema_title': 'Gauti siuntimo etiketę | {siteTitle}',
  'listing_order_seller_address_page.title': 'Gauti siuntimo etiketę',
  'listing_order_seller_address_page.return_page': 'Užsakymo būklė',
  'listing_order_seller_address_page.shipping.delivery_provider': 'DPD Express siuntimas',
  'listing_order_seller_address_page.shipping.provider_info': 'DPD durų prie durų paslauga',
  'listing_order_seller_address_page.shipping.pick_up_info': 'Pristatymo informacija',
  'listing_order_seller_address_page.shipping.add_delivery_info': 'Pridėti pristatymo informaciją',
  'listing_order_seller_address_page.shipping.save_address_error':
    'Nepavyko išsaugoti adreso, bandykite dar kartą vėliau.',
  'listing_order_seller_address_page.shipping.get_label_btn': 'Gauti siuntimo etiketę',

  'listing_order_courier_request_page.schema_title': 'Užsisakyti kurjerį | {siteTitle}',
  'listing_order_courier_request_page.title': 'Užsisakyti kurjerį',
  'listing_order_courier_request_page.return_page': 'Užsakymo būklė',
  'listing_order_courier_request_page.form_title': 'Užsisakyti kurjerį',
  'listing_order_courier_request_page.form_subtitle': 'Užsisakykite kurjerį iš savo adreso.',
  'listing_order_courier_request_page.form_date_label': 'Pristatymo data',
  'listing_order_courier_request_page.form_date_placeholder': 'Pasirinkite pristatymo datą',
  'listing_order_courier_request_page.form_date_required': 'Pasirinkite pristatymo datą',
  'listing_order_courier_request_page.form_time_label': 'Pristatymo laikas',
  'listing_order_courier_request_page.form_time_placeholder': 'Pasirinkite pristatymo laiką',
  'listing_order_courier_request_page.form_time_required': 'Pasirinkite pristatymo laiką',
  'listing_order_courier_request_page.request_pick_up_btn': 'Užsisakyti kurjerį',

  'orders_page.schema_title': 'Užsakymai | {siteTitle}',
  'orders_page.purchases': 'Pirkimai',
  'orders_page.sales': 'Pardavimai',

  'listing_stage_info_page.schema_title': 'Skelbimo informacija | {siteTitle}',
  'listing_stage_info_page.uploaded': 'Įkelta',
  'listing_stage_info_page.whats_next': 'Kas toliau?',
  'listing_stage_info_page.stage_1.title': 'Laukti užsakymo užklausos',
  'listing_stage_info_page.stage_1.description':
    'Gavus užklausą iš pirkėjo, turėsite 24 valandų laikotarpį ją priimti.',
  'listing_stage_info_page.stage_2.title': 'Pakuotė ir siuntimas',
  'listing_stage_info_page.stage_2.description': `Priėmus pirkėjo užklausą, turėsite 5 dienas supakuoti ir išsiųsti užsakymą. Siuntimo instrukcijos bus pateiktos.`,
  'listing_stage_info_page.stage_3.title': 'Mokėjimas',
  'listing_stage_info_page.stage_3.description':
    'Mokėjimas bus automatiškai apdorotas 48 valandas po to, kai prekė sėkmingai bus pristatyta pirkėjui.',

  'packaging_instructions_page.schema_title': 'Pakuotės instrukcijos | {siteTitle}',
  'packaging_instructions_page.title': 'Kaip supakuoti prekę',
  'packaging_instructions_page.return_page': 'Užsakymo būklė',

  'edit_listing_page.location.label': 'Vieta',
  'edit_listing_page.details.label': 'Daugiau',
  'edit_listing_page.categories.label': 'Kategorija',
  'edit_listing_page.general_details.label': 'Nuotraukos ir informacija',
  'edit_listing_page.show_listing_failed': 'Nepavyko pateikti',
  'edit_listing_page.update_failed': 'Nepavyko pateikti. Pabandyk dar kartą.',
  'edit_listing_page.upload_failed': 'Nepavyko įkelti.',
  'edit_listing_page.max_length': 'Turi būti {maxLength} simbolių ar mažiau',

  'edit_listing_page.location.tab_title': 'Parduok naują arba naudotą dviratį.',
  'edit_listing_page.location.address': 'Kur yra tavo dviratis?',
  'edit_listing_page.location.address_hint': 'Adresas bus matomas tik tau.',
  'edit_listing_page.location.address_not_recognized': 'Adresas nėra žinomas. Įvesk kitą adresą.',
  'edit_listing_page.location.address_placeholder': 'Įvesk adresą',
  'edit_listing_page.location.address_required': 'Turi įvesti adresą',
  'edit_listing_page.location.shipping_label': 'Siuntimas',
  'edit_listing_page.location.shipping_required': 'Pasirink siuntimo būdą',

  'edit_listing_page.categories.tab_title': 'Pasirink, kuris labiausiai atitinka tavo dviratį',
  'edit_listing_page.categories.type_select': 'Pasirink kategoriją',
  'edit_listing_page.categories.sub_category_required': 'Pasirink tipą',
  'edit_listing_page.categories.category_required': 'Turi pasirinkti dviračio kategoriją',

  'edit_listing_page.details.tab_title': 'Pagrindinė informacija apie dviratį',
  'edit_listing_page.details.frame_details': 'Rėmo informacija',
  'edit_listing_page.details.brand': 'Gamintojas',
  'edit_listing_page.details.brand_placeholder': 'Pasirink gamintoją',
  'edit_listing_page.details.brand_required': 'Turi pasirinkti gamintoją',
  'edit_listing_page.details.model': 'Modelis',
  'edit_listing_page.details.model_placeholder': 'Įvesk rėmo modelį',
  'edit_listing_page.details.model_required': 'Turi įvesti rėmo modelį',
  'edit_listing_page.details.year': 'Metai',
  'edit_listing_page.details.year_placeholder': 'Pasirink metus',
  'edit_listing_page.details.year_required': 'Turi pasirinkti metus',
  'edit_listing_page.details.frame_size': 'Rėmo dydis',
  'edit_listing_page.details.frame_size_placeholder': 'Pasirink rėmo dydį',
  'edit_listing_page.details.frame_size_required': 'Turi pasirinkti rėmo dydį',
  'edit_listing_page.details.frame_material': 'Rėmas',
  'edit_listing_page.details.frame_material_required': 'Pasirink rėmą',
  'edit_listing_page.details.frame_color': 'Spalva',
  'edit_listing_page.details.frame_color_required': 'Pasirink spalvą',
  'edit_listing_page.details.wheel_size': 'Ratų dydis',
  'edit_listing_page.details.wheel_size_info': 'Ratų diametras, dažnai nurodomas ant padangos',
  'edit_listing_page.details.wheel_size_placeholder': 'Pasirink ratų dydį',
  'edit_listing_page.details.wheel_size_required': 'Turi pasirinkti ratų dydį',
  'edit_listing_page.details.component_details': 'Detalių informacija',
  'edit_listing_page.details.component_details_placeholder':
    'Išvardink dviračio detales - šakę, bėgių sistemą, amortizatorius. Kaip pavyzdys - Shimano Dura-Ace bėgiai, Bontrager Aeolus Pro 3v TLR ratai',
  'edit_listing_page.details.component_details_required': 'Turi aprašyti dviračio detales',
  'edit_listing_page.details.frame_size_table_title': 'Dydžių lentelė',
  'edit_listing_page.details.frame_size_table_info':
    'Rėmo dydis dažniausiai yra nurodytas ant rėmo, esančio po sėdyne. Jei nepavyksta rasti, vadovaukitės lentelės duomenimis',
  'edit_listing_page.details.frame_size_table_header_1': 'Rėmo dydis',
  'edit_listing_page.details.frame_size_table_header_2': 'Rėmo dydis centimetrais',
  'edit_listing_page.details.wheel_size.hint': 'Ratų diametras, dažniausiai nurodomas ant padangos',

  'edit_listing_page.general_details.tab_title': 'Pridėk dviračio nuotraukas',
  'edit_listing_page.general_details.image_upload_failed': 'Nepavyko įkelti',
  'edit_listing_page.general_details.image_upload_over_limit': 'Didžiausias leistinas nuotraukos dydis 20MB',
  'edit_listing_page.general_details.image_required': 'Turi įkelti bent 3 nuotraukas',
  'edit_listing_page.general_details.image_upload.info': 'Įkelk bent 3 nuotraukas',
  'edit_listing_page.general_details.image_upload.from_device': 'Įkelti iš įrenginio',
  'edit_listing_page.general_details.image_upload.add_more': 'Pridėti daugiau',
  'edit_listing_page.general_details.image_upload.main_image': 'Pagrindinė nuotrauka',
  'edit_listing_page.general_details.title': 'Pavadinimas',
  'edit_listing_page.general_details.title_placeholder': 'pvz. Trek slash 9.7',
  'edit_listing_page.general_details.title_required': 'Turi įvesti pavadinimą',
  'edit_listing_page.general_details.condition': 'Būklė',
  'edit_listing_page.general_details.condition_required': 'Turi pasirinkti dviračio būklę',
  'edit_listing_page.general_details.receipt': 'Ar dviratis turi pirkimo dokumentus?',
  'edit_listing_page.general_details.receipt_required': 'Turi pasirinkti, ar dviratis turi pirkimo dokumentus',
  'edit_listing_page.general_details.price': 'Kaina',
  'edit_listing_page.general_details.placeholder': '€0.00',
  'edit_listing_page.general_details.price_required': 'Turi nurodyti kainą',
  'edit_listing_page.general_details.price_too_low': 'Kaina negali būti mažesnė nei {minPrice}.',
  'edit_listing_page.general_details.price_too_high': 'Kaina negali būti didesnė nei {maxPrice}.',
  'edit_listing_page.general_details.saved_image_alt': 'Saved listing image',
  'parts_listing_page.general_details.image_upload.info': 'Įkelk bent 1 nuotrauką',

  'edit_listing_page.payment_info.tab_title': 'Mokėjimo informacija',
  'edit_listing_page.payment_info.label': 'Mokėjimo informacija',
  'edit_listing_page.payment_info.info_section_title':
    'Užbaikite savo profilį, kad galėtumėte gauti mokėjimą už dviratį',
  'edit_listing_page.payment_info.info_section_message':
    'Norėdami gauti mokėjimą už dviratį, įveskite savo duomenis žemiau. Tai reikės padaryti tik vieną kartą.',
  'edit_listing_page.payment_info.first_name': 'Vardas',
  'edit_listing_page.payment_info.first_name_placeholder': 'Vardas',
  'edit_listing_page.payment_info.first_name_required': 'Turi įvesti vardą',
  'edit_listing_page.payment_info.last_name': 'Pavardė',
  'edit_listing_page.payment_info.last_name_placeholder': 'Pavardė',
  'edit_listing_page.payment_info.last_name_required': 'Turi įvesti pavardę',
  'edit_listing_page.payment_info.date_of_birth': 'Gimimo data',
  'edit_listing_page.payment_info.date_of_birth_placeholder': 'Gimimo data',
  'edit_listing_page.payment_info.date_of_birth_required': 'Turi įvesti gimimo datą',
  'edit_listing_page.payment_info.address_line_1': 'Adresas',
  'edit_listing_page.payment_info.address_line_1_placeholder': 'Adresas',
  'edit_listing_page.payment_info.address_line_1_required': 'Turi įvesti adresą',
  'edit_listing_page.payment_info.address_line_2': 'Papildoma informacija',
  'edit_listing_page.payment_info.address_line_2_placeholder': 'Papildoma informacija',
  'edit_listing_page.payment_info.postal_code': 'Pašto kodas',
  'edit_listing_page.payment_info.postal_code_placeholder': 'Pašto kodas',
  'edit_listing_page.payment_info.postal_code_required': 'Turi įvesti pašto kodą',
  'edit_listing_page.payment_info.city': 'Miestas',
  'edit_listing_page.payment_info.city_placeholder': 'Miestas',
  'edit_listing_page.payment_info.city_required': 'Turi įvesti miestą',

  'FieldBirthdayInput.birthdayDatePlaceholder': 'DD',
  'FieldBirthdayInput.birthdayMonthPlaceholder': 'MM',
  'FieldBirthdayInput.birthdayYearPlaceholder': 'YYYY',

  'edit_listing_page.payment_info.terms_and_conditions_required': 'Turi sutikti su taisyklėmis ir sąlygomis',
  'edit_listing_page.payment_info.payment_provider_error': 'Kažkas nutiko su mokėjimo sistema. Bandyk dar kartą.',
  'edit_listing_page.payment_info.generic_error': 'Kažkas nutiko. Bandyk dar kartą.',

  'your_listings_page.edit_listing': 'Koreguoti',
  'your_listings_page.delete_listing': 'Ištrinti',
  'your_listings_page.delete_draft': 'Ištrinti',
  'your_listings_page.title': 'Dviračiai pardavimui',
  'your_listings_page.schema_title': 'Tavo skelbimai',
  'your_listings_page.delete_modal.title': 'Ar tikrai nori ištrinti?',
  'your_listings_page.delete_modal.delete_action': 'Ištrinti',
  'your_listings_page.delete_modal.cancel_action': 'Atšaukti',

  'inbox_page.label': 'Žinutės',
  'inbox_page.empty_state.message': 'Dar neturi jokių žinučių',
  'inbox_page.message_input.placeholder': 'Išsiųsk žinutę',

  'about_page.title': 'Apie mus',
  'about_page.mission_statement':
    'Mūsų misija yra padaryti dviračius prieinamus ir įperkamus visiems, nepaisant amžiaus, lyties, fizinio pasiruošimo, socialinio ar ekonominio statuso. Mūsų aistra - skatinti tvarų transportą ir sveiką gyvenimo būdą. Mūsų platforma jungia pirkėjus ir pardavėjus, pateikiant platų, aukštos kokybės naudotų dviračių asortimentą prieinamomis kainomis.',
  'about_page.sustainability_title': 'Tvarumas',
  'about_page.sustainability_statement':
    'Važinėjimas dviračiu ir naudoto dviračio pirkimas yra tvarus pasirinkimas, naudingas ir žmonėms, ir aplinkai. Važiuojant dviračiu mažėja anglies dioksido išmetimas ir oro tarša, o perkant naudotą dviratį skatinamas sąmoningas gyvenimo būdas ir mažinamas atliekų kiekis. Be to, važinėjimas dviračiu skatina sveikesnį gyvenimo būdą ir mažina sveikatos priežiūros sistemai tenkančią naštą. Šie tvarūs pasirinkimai prisideda prie sveikesnės ir tvaresnės visų ateities.',
  'about_page.our_work_title': 'Mūsų darbai',
  'about_page.our_work_statement':
    'Esame čia nuo pat pirmųjų kalnų nusileidimo lenktynių Lietuvoje 2008 m.  Nuo tada atradome aistrą dviračių sportui ir jo bendruomenei. Visada stengėmės sukurti draugišką aplinką, kurioje kiekvienas galėtų pajausti važiavimo dviračiu džiaugsmą. Savo aistrą dviračių sportui stengiamės skleisti dalindamiesi savo sukaupta patirtimi, kurdami filmus, organizuodami renginius ir mokydami savo išmoktomis pamokomis. Mes įsitikinę, kad meile važinėti dviračiu reikia dalintis.',
  'about_page.our_team_title': 'Mūsų komanda',
  'about_page.our_team_statement':
    'Esame užsidegusi komanda, įsikūrusi Vilniuje, Lietuvoje, ir užsiimame viskuo, kas susiję su dviračių sportu. Siekiame gerinti važiavimo dviračiu patirtį ir skatinti tvarius transporto sprendimus. Dirbkime kartu, kad sukurtume šviesesnę ateitį sau ir kitiems',

  'profile_settings.title': 'Profilio nustatymai',
  'profile_settings.first_name.label': 'Vardas',
  'profile_settings.first_name.placeholder': 'Vardas',
  'profile_settings.first_name.required': 'Turi įvesti vardą',
  'profile_settings.last_name.label': 'Pavardė',
  'profile_settings.last_name.placeholder': 'Pavardė',
  'profile_settings.last_name.required': 'Turi įvesti pavardę',
  'profile_settings.user_name.label': 'Vartotojo vardas',
  'profile_settings.user_name.placeholder': 'Vartotojo vardas',
  'profile_settings.user_name.required': 'Turi pasirinkti vartotojo vardą',
  'profile_settings.bio.label': 'Aprašymas',
  'profile_settings.bio.placeholder': 'Papasakok apie save',
  'profile_settings.save': 'Išsaugoti',
  'profile_settings.profile_picture.file_too_large': 'Įkelk mažesnio dydžio nuotrauką',
  'profile_settings.profile_picture.upload_failed': 'Nepavyko įkelti',
  'profile_settings.profile_picture.edit': 'Keisti nuotrauką',
  'profile_settings.profile_update_failed': 'Nepavyko išsaugoti pakeitimų. Bandyk dar kartą.',

  'account_settings.label': 'Nustatymai',
  'account_settings.email.heading': 'Kontaktinės detalės',
  'account_settings.email.label': 'El. paštas',
  'account_settings.save_changes': 'Išsaugoti pakeitimus',
  'account_settings.email.invalid': 'Pašto adresas nevalidus',
  'account_settings.confirm_email_change_label': 'Įvesk savo dabartinį slaptažodį',
  'account_settings.confirm_password.label': 'Slaptažodis',
  'account_settings.confirm_password.placeholder': 'Slaptažodis',
  'account_settings.confirm_password.too_short': 'Slaptažodis turi būti ne trumpesnis nei {minLength} simboliai',
  'account_settings.confirm_password.required': 'Įvesk slaptažodį',
  'account_settings.confirm_password.failed': 'Slaptažodis neteisingas',

  'account_settings.phone.label': 'Telefono numeris',
  'account_settings.phone.placeholder': 'Įvesk telefono numerį',
  'account_settings.phone.error': 'Telefono numeris neteisingas',
  'account_settings.phone.contact_allowed.title': 'Rodyti numerį skelbimo aprašyme',
  'account_settings.phone.contact_allowed.description': 'Telefono numeris bus matomas aktyviuose skelbimuose',

  'password_recovery_page.forgot_password.title': 'Pamiršai slaptažodį?',
  'password_recovery_page.forgot_password.message':
    'Įvesk el. paštą, kuriuo registravaisi. Atsiųsime tau laišką su nuoroda pasikeisti slaptažodį.',
  'password_recovery_page.forgot_password.email.label': 'El. paštas',
  'password_recovery_page.forgot_password.email.placeholder': 'El. paštas',
  'password_recovery_page.forgot_password.email.required': 'Turi įvesti el. paštą',
  'password_recovery_page.forgot_password.email.not_found': 'El. paštas nerastas',
  'password_recovery_page.forgot_password.email.invalid': 'El. paštas neteisingas',
  'password_recovery_page.forgot_password.submit': 'Siųsti',

  'PasswordRecoveryForm.emailInvalid': 'Turi įvesti teisingą el. paštą',
  'PasswordRecoveryForm.emailLabel': 'El. paštas',
  'PasswordRecoveryForm.emailNotFound':
    'Hmm... Nepavyko rasti paskyros su nurodytu el. paštu. Patikrink, ar įvedei teisingai ir bandyk dar kartą.',
  'PasswordRecoveryForm.emailPlaceholder': 'john.doe@example.com',
  'PasswordRecoveryForm.emailRequired': 'Turi įvesti el. paštą',
  'PasswordRecoveryForm.loginLinkInfo': 'Netikėtai prisiminei savo slaptažodį? {loginLink}',
  'PasswordRecoveryForm.loginLinkText': 'Prisijungti',
  'PasswordRecoveryForm.sendInstructions': 'Siųsti instrukcijas',
  'PasswordRecoveryPage.actionFailedMessage': 'Kažkas nutiko. Bandyk dar kartą.',
  'PasswordRecoveryPage.actionFailedTitle': 'Oops!',
  'PasswordRecoveryPage.emailSubmittedMessage':
    'Instrukcijos, kaip atkurti slaptažodį nusiųstos į tavo el. paštą, {submittedEmailText}.',
  'PasswordRecoveryPage.emailSubmittedTitle': 'Patikrinti žinutes',
  'PasswordRecoveryPage.fixEmailInfo': 'Oops, klaidelė el.pašte? {fixEmailLink}',
  'PasswordRecoveryPage.fixEmailLinkText': 'Pataisyk.',
  'PasswordRecoveryPage.forgotPasswordMessage':
    'Nesijaudink! Įvesk el. paštą, su kuriuo registravaisi ir atsiųsime instrukcijas, kaip atkurti savo slaptažodį.',
  'PasswordRecoveryPage.forgotPasswordTitle': 'Pamiršai slaptažodį?',
  'PasswordRecoveryPage.resendEmailInfo': 'Negavai laiško? {resendEmailLink}',
  'PasswordRecoveryPage.resendEmailLinkText': 'Siųsti dar kartą.',
  'PasswordRecoveryPage.resendingEmailInfo': 'Siunčiam instrukcijas..',
  'PasswordRecoveryPage.title': 'Naujas slaptažodis',

  'favorite_listings_page.label': 'Pamėgti skelbimai',
  'favorite_listings_page.search_button': 'Dviračių paieška',
  'favorite_listings_page.empty_state.title': 'Tavo pamėgti dviračiai',
  'favorite_listings_page.empty_state.description': 'Kai pamėgsi dviratį, jis pasirodys čia',
  'favorite_listings_page.schema_title': 'Pamėgti skelbimai',

  'pagination.previous': 'Praeitas puslapir',
  'pagination.next': 'Sekantis puslapis',
  'pagination.to_page': 'Eiti į {page} puslapį',

  'cookies.title': 'Svetainėje naudojame slapukus',
  'cookies.consent_message':
    'Gearro naudoja slapukus siekdami gerinti jūsų naršymo patirtį, suasmeninti turinį ir reklamas. Tęsdami naršymą sutinkate su privatumo politika.',
  'cookies.accept': 'Sutinku',

  'categories.all': 'Dviračiai ir dalys',
  'categories.none': 'Visos kategorijos',
  'categories.bike': 'Dviračiai',
  'categories.parts': 'Dalys',

  'categories.frame': 'Rėmas',
  'categories.suspension': 'Amortizatoriai',
  'categories.drivetrain': 'Grupė',
  'categories.brakes': 'Stabdžiai',
  'categories.wheels': 'Ratai',
  'categories.tyres_tubes': 'Padangos',
  'sub_categories.tyres_tubes': 'Padangos',
  'categories.cockpit': 'Vairo dalys',
  'categories.seat': 'Sedynės',

  'config.labels.subCategory': 'Subkategorija',
  'config.labels.discipline': 'Tipas',
  'config.labels.frontTravel': 'Priekinė eiga',
  'config.labels.rearTravel': 'Galinė eiga',
  'config.labels.dropperTravel': 'Eiga',
  'config.labels.dropperDiameter': 'Diametras',
  'config.labels.dropperActivation': 'Aktyvacija',
  'config.labels.catalogTree': 'Kategorija',
  'config.labels.hubStandard': 'Stebulės standartas',
  'config.labels.frontHubStandard': 'Priekinė stebulė',
  'config.labels.rearHubStandard': 'Galinė stebulė',
  'config.labels.suspensionType': 'Amortizacijos tipas',
  'config.labels.suspensionMounting': 'Amortizacijos tvirtinimas',
  'config.labels.suspensionLength': 'Ilgis',
  'config.labels.suspensionStroke': 'Eiga',
  'config.labels.axleDimension': 'Ašies matmenys',
  'config.labels.gears': 'Pavaros',
  'config.labels.frontGears': 'Priekinės pavaros',
  'config.labels.rearGears': 'Galinės pavaros',
  'config.labels.placement': 'Tipas',
  'config.labels.activation': 'Aktyvacija',
  'config.labels.chainringMounting': 'Dantračių tvirtinimas',
  'config.labels.bottomBracketMounting': 'Tvirtinimas',
  'config.labels.pedals': 'Tipas',
  'config.labels.frontWheelSize': 'Priekinio rato dydis',
  'config.labels.rearWheelSize': 'Galinio rato dydis',
  'config.labels.wheelsPlacement': 'Tipas',
  'config.labels.brakeType': 'Tipas',
  'config.labels.brakesActivation': 'Aktyvacija',
  'config.labels.hubsPlacement': 'Tipas',
  'config.labels.handlebarType': 'Tipas',
  'config.labels.gripsType': 'Tipas',
  'config.labels.steererType': 'Tipas',
  'config.labels.crankArmLength': 'Švaistiklio ilgis',
  'config.labels.chainringTeeth': 'Dantračiai',
  'config.labels.handlebarWidth': 'Vairo plotis',

  'suspension_mounting.trunnion': 'Trunnion',
  'suspension_mounting.standard': 'Standard',

  'discipline.xc_marathon': 'XC / Marathon',
  'discipline.trail_all_mountain': 'Trail / All-Mountain',
  'discipline.enduro': 'Enduro',
  'discipline.downhill': 'Downhill',

  'hub_standard.15_110': '15 x 110mm (Boost)',
  'hub_standard.20_110': '20 x 110mm (Boost)',
  'hub_standard.9_100': '9 x 100mm',
  'hub_standard.15_100': '15 x 100mm',
  'hub_standard.20_100': '20 x 100mm',
  'hub_standard.9_135': '9 x 135mm',
  'hub_standard.10_135': '10 x 135mm',
  'hub_standard.15_142': '15 x 142mm',
  'hub_standard.15_150': '15 x 150mm',
  'hub_standard.10_170': '10 x 170mm',
  'hub_standard.12_190': '12 x 190mm',
  'hub_standard.12_197': '12 x 197mm',

  'suspension_type.air': 'Orinė',
  'suspension_type.coil': 'Spyruoklinė',

  'axle_dimension.30': '30mm',
  'axle_dimension.29_9': '29.9mm',
  'axle_dimension.22_24': '22/24mm (DUB)',
  'axle_dimension.25': '25mm',
  'axle_dimension.24': '24mm',
  'axle_dimension.19': '19mm',
  'axle_dimension.square_taper': 'Square taper',
  'axle_dimension.octalink': 'Octalink',
  'axle_dimension.m30': 'M30',
  'axle_dimension.isis': 'ISIS',
  'axle_dimension.american': 'American',
  'axle_dimension.mid': 'Mid',

  'gears.1': '1',
  'gears.2': '2',
  'gears.3': '3',
  'gears.4': '4',
  'gears.5': '5',
  'gears.6': '6',
  'gears.7': '7',
  'gears.8': '8',
  'gears.9': '9',
  'gears.10': '10',
  'gears.11': '11',
  'gears.12': '12',
  'gears.13': '13',

  'placement.front': 'Priekis',
  'placement.rear': 'Galas',
  'placement.set': 'Rinkinys',

  'front_travel.placeholder': 'Įvesk priekinę eigą',
  'rear_travel.placeholder': 'Įvesk galinę eigą',

  'activation.mechanical': 'Mechaninė',
  'activation.electric': 'Elektrinė',

  'chainring_mounting.3_arm': '3-arm',
  'chainring_mounting.4_arm': '4-arm',
  'chainring_mounting.5_arm': '5-arm',

  'bottom_bracket_mounting.bsa': 'BSA',
  'bottom_bracket_mounting.ita': 'ITA',
  'bottom_bracket_mounting.pressfit': 'Pressfit',

  'pedals.flat': 'Platforminiai',
  'pedals.clipless': 'Įsegami',
  'pedals.hybrid': 'Hibridiniai',

  'brakes_activation.hydraulic': 'Hidraulinis',
  'brakes_activation.mechanical': 'Mechaninis',

  'handlebar_type.mtb': 'MTB',
  'handlebar_type.dropbar': 'Dropbar',
  'handlebar_type.bmx': 'BMX',
  'handlebar_type.city_trekking': 'City/Trekking',

  'grips_type.lock_on': 'Užspaudžiami',
  'grips_type.push_on': 'Užstumiami',
  'grips_type.bar_tape': 'Vairo juosta',

  'steerer_type.1_5': '1.5”',
  'steerer_type.1_8': '1-1/8”',
  'steerer_type.threaded': 'Threaded',
  'steerer_type.tapered': 'Tapered',

  'dropper_diameter.27_2': '27.2mm',
  'dropper_diameter.28_6': '28.6mm',
  'dropper_diameter.30_9': '30.9mm',
  'dropper_diameter.31_6': '31.6mm',
  'dropper_diameter.31_8': '31.8mm',
  'dropper_diameter.34_9': '34.9mm',

  'dropper_activation.mechanical': 'Mechaninis',
  'dropper_activation.electric': 'Elektrinis',
  'dropper_activation.hydraulic': 'Hidraulinis',

  'rotor_mounting.6_hole': '6-Hole',
  'rotor_mounting.centerlock': 'Centerlock',

  'rotor_placement.front': 'Priekinis',
  'rotor_placement.rear': 'Galinis',
  'rotor_placement.set': 'Komplektas',

  'wheels_placement.front': 'Priekinis',
  'wheels_placement.rear': 'Galinis',
  'wheels_placement.set': 'Komplektas',

  'hubs_placement.front': 'Priekinis',
  'hubs_placement.rear': 'Galinis',
  'hubs_placement.set': 'Komplektas',

  'condition.used_part': 'Naudotas',
  'condition.good_part': 'Geras',
  'condition.new_part': 'Naujas',

  'condition.used_part.label': '✔ Naudotas, su matomu nusidevėjimu ',
  'condition.good_part.label': '✔ Lengvai naudotas, tačiau geros būklės',
  'condition.new_part.label': '✔ Idealios būklės, ankščiau nenaudotas',

  'new_listing_page.page_title': 'Ką parduosi?',
  'new_listing_page.type.bike': 'Dviratį',
  'new_listing_page.description.bike': 'Pilną dviratį, bet kokios būklės.',
  'new_listing_page.type.part': 'Dviračio dalis',
  'new_listing_page.description.part': 'Rėmą, šakę, ratus arba kitas dalis.',

  'parts_listing_page.details.label': 'Informacija',
  'parts_listing_page.categories.label': 'Tipas',
  'parts_listing_page.general_details.label': 'Nuotraukos ir informacija',
  'parts_listing_page.specifications.label': 'Specifikacija',
  'parts_listing_page.payment_information.label': 'Mokėjimo informacija',

  'parts_listing_page.categories.tab_title': 'Pasirink ką parduodi.',
  'parts_listing_page.details.tab_title': 'Pateik informaciją apie dalį.',
  'parts_listing_page.specification.tab_title': 'Pateik dalies specifikacijas.',

  'parts_listing_page.specification.panel_description': 'Padidink pardavimo tikimybę pateikdamas papildomas detales.',

  'parts_listing_page.details.sub_category': 'Subkategorija',
  'parts_listing_page.details.sub_category_required': 'Pasirink subkategoriją',
  'parts_listing_page.details.sub_category_placeholder': 'Pasirink subkategoriją',
  'parts_listing_page.details.brand': 'Gamintojas',
  'parts_listing_page.details.brand_placeholder': 'Pasirink gamintoją',
  'parts_listing_page.details.brand_required': 'Turi pasirinkti gamintoją',
  'parts_listing_page.details.model': 'Modelis',
  'parts_listing_page.details.model_placeholder': 'Įvesk dalies modelį',
  'parts_listing_page.details.model_required': 'Turi įvesti dalies modelį',
  'parts_listing_page.details.year_placeholder': 'Pasirink metus',

  'parts_listing_page.specification.more_details': 'Daugiau detalių',
  'parts_listing_page.specifications.frame_size': 'Dydis',
  'parts_listing_page.specifications.frame_size_placeholder': 'Pasirink dydį',
  'parts_listing_page.specifications.frame_size_required': 'Pasirink rėmo dydį',
  'parts_listing_page.specifications.frame_material': 'Medžiaga',
  'parts_listing_page.specifications.frame_material_placeholder': 'Pasirink medžiagą',
  'parts_listing_page.specifications.frame_material_required': 'Pasirink medžiagą',
  'parts_listing_page.specifications.wheel_size': 'Ratų dydis',
  'parts_listing_page.specifications.wheel_size_placeholder': 'Pasirink ratų dydį',
  'parts_listing_page.specifications.wheel_size_required': 'Pasirink ratų dydį',
  'parts_listing_page.specifications.front_travel': 'Priekinė eiga',
  'parts_listing_page.specifications.front_travel_placeholder': 'Įrašyk priekinę eigą',
  'parts_listing_page.specifications.front_travel_required': 'Įrašyk galinę eigą',
  'parts_listing_page.specifications.rear_travel': 'Galinė eiga',
  'parts_listing_page.specifications.rear_travel_placeholder': 'Įrašyk galinę eigą',
  'parts_listing_page.specifications.rear_travel_required': 'Įrašyk galinę eigą',
  'parts_listing_page.specifications.discipline': 'Disciplina',
  'parts_listing_page.specifications.discipline_placeholder': 'Pasirink disciplina',
  'parts_listing_page.specifications.hub_standard': 'Stebulės standartas',
  'parts_listing_page.specifications.hub_standard_placeholder': 'Pasirink stebulės standartas',
  'parts_listing_page.specifications.suspension_type': 'Tipas',
  'parts_listing_page.specifications.suspension_mounting': 'Tvirtinimas',
  'parts_listing_page.specifications.suspension_length': 'Ilgis',
  'parts_listing_page.specifications.suspension_length_placeholder': 'Įvesk ilgį',
  'parts_listing_page.specifications.suspension_stroke': 'Amortizacijos eiga',
  'parts_listing_page.specifications.suspension_stroke_placeholder': 'Įvesk eigą',
  'parts_listing_page.specifications.axle_dimension': 'Ašies diametras',
  'parts_listing_page.specifications.axle_dimension_placeholder': 'Pasirink ašies diametrą',
  'parts_listing_page.specifications.crank_arm_length': 'Švaistiklio ilgis',
  'parts_listing_page.specifications.crank_arm_length_placeholder': 'Įvesk švaistiklio ilgį',
  'parts_listing_page.specifications.gears': 'Pavaros',
  'parts_listing_page.specifications.gears_placeholder': 'Pasirink pavaras',
  'parts_listing_page.specifications.placement': 'Tipas',
  'parts_listing_page.specifications.activation': 'Atkyvacija',
  'parts_listing_page.specifications.front_gears': 'Priekinės pavaros',
  'parts_listing_page.specifications.front_gears_placeholder': 'Pasirink priekines pavaras',
  'parts_listing_page.specifications.rear_gears': 'Galinės pavaros',
  'parts_listing_page.specifications.rear_gears_placeholder': 'Pasirink galines pavaras',
  'parts_listing_page.specifications.chainring_mounting': 'Tvirtinimas',
  'parts_listing_page.specifications.chainring_mounting_placeholder': 'Pasirink tvirtinimą',
  'parts_listing_page.specifications.chainring_teeth': 'Dantračiai',
  'parts_listing_page.specifications.chainring_teeth_placeholder': 'Įvesk dantračių kiekį',
  'parts_listing_page.specifications.bottom_bracket_mounting': 'Tvirtinimas',
  'parts_listing_page.specifications.bottom_bracket_mounting_placeholder': 'Pasirink tvirtinimą',
  'parts_listing_page.specifications.bottom_bracket_width': 'Plotis',
  'parts_listing_page.specifications.bottom_bracket_width_placeholder': 'Įvesk plotį',
  'parts_listing_page.specifications.pedals': 'Tipas',
  'parts_listing_page.specifications.pedals_placeholder': 'Pasirink tipą',
  'parts_listing_page.specifications.brake_type': 'Tipas',
  'parts_listing_page.specifications.brakes_activation': 'Aktyvacija',
  'parts_listing_page.specifications.rotor_mounting': 'Tvirtinimas',
  'parts_listing_page.specifications.rotor_placement': 'Tipas',
  'parts_listing_page.specifications.front_disk_diameter': 'Priekinio disko diametras',
  'parts_listing_page.specifications.front_disk_diameter_placeholder': 'Įvesk disko diametrą',
  'parts_listing_page.specifications.rear_disk_diameter': 'Galinio disko diametras',
  'parts_listing_page.specifications.rear_disk_diameter_placeholder': 'Įvesk disko diametrą',
  'parts_listing_page.specifications.wheels_placement': 'Tipas',
  'parts_listing_page.specifications.front_wheel_size': 'Priekinio rato dydis',
  'parts_listing_page.specifications.front_wheel_size_placeholder': 'Pasirink rato dydį',
  'parts_listing_page.specifications.rear_wheel_size': 'Galinio rato dydis',
  'parts_listing_page.specifications.rear_wheel_size_placeholder': 'Pasirink rato dydį',
  'parts_listing_page.specifications.hubs_placement': 'Tipas',
  'parts_listing_page.specifications.front_hub_standard': 'Priekinė stebulė',
  'parts_listing_page.specifications.front_hub_standard_placeholder': 'Pasirink stebulės matmenis',
  'parts_listing_page.specifications.rear_hub_standard': 'Galinė stebulė',
  'parts_listing_page.specifications.rear_hub_standard_placeholder': 'Pasirink stebulės matmenis',
  'parts_listing_page.specifications.inner_rim_width': 'Vidinis ratlankio plotis',
  'parts_listing_page.specifications.inner_rim_width_placeholder': 'Įvesk ratlankio plotį',
  'parts_listing_page.specifications.handlebar_type': 'Tipas',
  'parts_listing_page.specifications.handlebar_type_placeholder': 'Pasirink vairo tipą',
  'parts_listing_page.specifications.handlebar_width': 'Plotis',
  'parts_listing_page.specifications.handlebar_width_placeholder': 'Įvesk vairo plotį',
  'parts_listing_page.specifications.stem_length': 'Ilgis',
  'parts_listing_page.specifications.stem_length_placeholder': 'Įvesk vairo iškyšos ilgį',
  'parts_listing_page.specifications.grips_type': 'Tipas',
  'parts_listing_page.specifications.grips_type_placeholder': 'Pasirink tipą',
  'parts_listing_page.specifications.steerer_type': "'Steerer' tipas",
  'parts_listing_page.specifications.steerer_type_placeholder': 'Pasirink tipą',
  'parts_listing_page.specifications.dropper_travel': 'Eiga',
  'parts_listing_page.specifications.dropper_travel_placeholder': 'Įvesk eigą',
  'parts_listing_page.specifications.dropper_diameter': 'Diametras',
  'parts_listing_page.specifications.dropper_diameter_placeholder': 'Pasirink diametrą',
  'parts_listing_page.specifications.dropper_activation': 'Aktyvacija',
  'parts_listing_page.specifications.dropper_activation_placeholder': 'Pasirink aktyvaciją',
  'parts_listing_page.specifications.seat_length': 'Ilgis',
  'parts_listing_page.specifications.seat_length_placeholder': 'Įvesk iškyšos ilgį',
  'parts_listing_page.specifications.brake_type_placeholder': 'Pasirink tipą',

  'parts_listing_page.general_details.tab_title': 'Pridėk dalies nuotraukas',
  'parts_listing_page.general_details.description.label': 'Aprašas',
  'parts_listing_page.general_details.description.placeholder':
    'Pateik informaciją apie dalies trūkumus, įpakavimą, sąskaitas ar kitas svarbias detales.',
  'parts_listing_page.general_details.description.required': 'Pateik informaciją apie dalį',
  'parts_listing_page.location.label': 'Lokacija',
  'parts_listing_page.general_details.shipping.label': 'Siuntimas',
  'parts_listing_page.general_details.shipping.required': 'Pasirink siuntimą',

  'sub_categories.frame_full_suspension': 'Full suspension',
  'sub_categories.frame_hard_tail_mtb': 'Hardtail MTB',
  'sub_categories.frame_road': 'Plento',
  'sub_categories.frame_gravel': 'Gravel',
  'sub_categories.frame_full_suspension_e_bike': 'Full suspension E-Bike',
  'sub_categories.frame_hard_tail_e_bike': 'Hardtail E-Bike',
  'sub_categories.frame_city': 'Miesto',
  'sub_categories.frame_dirt_n_street': 'Dirt & Street',

  'sub_categories.suspension_rigid_fork': 'Rigid-šakė',
  'sub_categories.suspension_suspension_fork': 'Priekinio amortizatoriaus šakė',
  'sub_categories.suspension_rear': 'Galinis amortizatorius',

  'sub_categories.drivetrain_crank': 'Švaistiklis',
  'sub_categories.drivetrain_chain': 'Grandinė',
  'sub_categories.drivetrain_cassette': 'Kasetė',
  'sub_categories.drivetrain_shifter': 'Pavarų rankenėlė',
  'sub_categories.drivetrain_derailleur': 'Pavarų perjungėjas',
  'sub_categories.drivetrain_chainring': 'Dantračiai',
  'sub_categories.drivetrain_bottom_bracket': 'Centrinė ašis',
  'sub_categories.drivetrain_chain_guides': 'Chain guide',
  'sub_categories.drivetrain_pedals': 'Pedalai',

  'sub_categories.brakes_disk': 'Diskiniai stabdžiai',
  'sub_categories.brakes_rim': 'V-Brake tipo stabdžiai',
  'sub_categories.brakes_rotor': 'Stabdžių diskai',
  'sub_categories.brakes_other': 'Kita',

  'sub_categories.wheels_complete': 'Ratai',
  'sub_categories.wheels_hubs': 'Stebulės',
  'sub_categories.wheels_rims': 'Ratlankiai',

  'sub_categories.cockpit_handlebar': 'Vairas',
  'sub_categories.cockpit_stem': 'Vairo iškyša',
  'sub_categories.cockpit_grips': 'Vairo rankenėlės',
  'sub_categories.cockpit_headset': 'Vairo kolonėlė',

  'sub_categories.seat_dropper_post': 'Reguliuojama balnelio iškyša',
  'sub_categories.seat_post': 'Balnelio iškyša',
  'sub_categories.seat_saddle': 'Balnelis',
  'sub_categories.seat_other': 'Kita',

  'landing_page.view_bikes_and_parts': 'Rask dviratį ar dalis',
  'landing_page.buy_bike_button': 'Rask dviratį',
  'landing_page.buy_parts_button': 'Rask dalis',
  'landing_page.most_recent_part_label': 'Dviračių dalys',
  'landing_page.popular_brands': 'Populiariausi gamintojai',

  'validators.min_max_value': 'Įveskite reikšmę tarp {minValue} ir {maxValue}',
  'validators.min_max_value.invalid_type.numeric': 'Įveskite skaitinę reikšmę',

  'favorite_listings_page.search_button.bike': 'Ieškok dvirašių',
  'favorite_listings_page.search_button.parts': 'Ieškok dalių',
  'favorite_listings_page.empty_state.bike.title': 'Tavo pamėgti dviračiai',
  'favorite_listings_page.empty_state.bike.description': 'Kai pamėgsi dviratį, jis pasirodys čia',
  'favorite_listings_page.empty_state.parts.title': 'Tavo pamėgtos dalys',
  'favorite_listings_page.empty_state.parts.description': 'Kai pamėgsi dalį, ji pasirodys čia',

  'favorite_listings_page.tabs.bikes': 'Dviračiai',
  'favorite_listings_page.tabs.parts': 'Dalys',

  'new_listing_page.schema_title': 'Naujas skelbimas',

  'about_page.schema_title': 'Apie mus | {siteTitle}',
  'about_page.schema_description': 'Apie Gearro',
  'listing_page.schema_title': '{title} | {siteTitle}',
  'profile_page.schema_title': '{name} | {siteTitle}',
  'search_page.schema_for_search': 'užklausa',
  'page.schema_title': 'Pirk ir parduok | {siteTitle}',
  'page.schema_description': 'Didžiausia internetinė dviratininkų bendruomenė.',
  'edit_listing_page.schema_title': 'Įkelk dviratį',
  'password_recovery_page.title': 'Naujas slaptažodis',
  'inbox_page.schema_title': 'Žinutės',

  'search_page.schema_title': 'Atraskite naudotus dviračius | Gearro',
  'search_page.schema_description': 'Pirkite ir parduokite naudotus dviračius Gearro.',
  'search_page.schema_title.bike': 'Atraskite savo kitą dviratį: Naudoti dviračiai | Gearro',
  'search_page.schema_description.bike':
    'Atraskite platų kokybiškų naudotų dviračių asortimentą Gearro. Raskite idealų plento, kalnų ar gravel dviratį su saugiais apmokėjimais ir pirkėjų apsauga. Pradėkite savo dviračių nuotykius jau šiandien.',
  'search_page.schema_title.bike.road': 'Aukštos kokybės plento dviračiai | Gearro',
  'search_page.schema_description.bike.road':
    'Aukščiausios klasės plento dviračiai. Peržiūrėkite mūsų asortimentą ir ieškokite lengvų, aerodinamiškų dizaino dviračių Gearro.',
  'search_page.schema_title.bike.mountain': 'Kalnų dviračiai | MTB | Gearro',
  'search_page.schema_description.bike.mountain':
    'Įveikite sunkiausias trasas su kalnų dviračiais. Mūsų Gearro asortimentas atitinka visus jūsų bekelės dviračių poreikius.',
  'search_page.schema_title.bike.gravel': 'Gravel dviračiai | Gearro',
  'search_page.schema_description.bike.gravel':
    'Keliaukite ne tik asfaltuotais keliais su universaliais gravel dviračiais, sukurtais patogumui ir atsparumui įvairiose vietovėse. Su Gearro atraskite daugiau.',
  'search_page.schema_title.bike.city': 'Parduodami miesto dviračiai | Miesto dviračiai | Gearro',
  'search_page.schema_description.bike.city': 'Aukščiausios kokybės miesto dviračių Gearro.',
  'search_page.schema_title.bike.electric': 'Elektriniai dviračiai | E-Bikes | Gearro',
  'search_page.schema_description.bike.electric':
    'Paspartinkite savo kelionę su elektriniais dviračiais, siūlančiais lengvą ir greitą važiavimą. Gearro atraskite tobulą el. dviratį, tinkantį jūsų gyvenimo būdui.',
  'search_page.schema_title.bike.bmx_dirt': 'BMX ir dirt dviračiai | Gearro',
  'search_page.schema_description.bike.bmx_dirt': 'Gearro rask BMX ir Dirt dviračius.',
  'search_page.schema_title.bike.folding': 'Sulankstomi dviračiai | Portable Bikes | Gearro',
  'search_page.schema_description.bike.folding':
    'Maksimaliai patogūs mūsų sulankstomieji dviračiai. Idealiai tinka važiuoti į darbą ir laikyti ankštose vietose, raskite savo dviratį Gearro.',
  'search_page.schema_title.bike.other': 'Unikalūs ir specialūs dviračiai | Raskite savo tobulą dviratį | Gearro',
  'search_page.schema_description.bike.other':
    'Ieškote kažko kitokio? Raskite unikalių ir specializuotų dviračių visiems pageidavimams Gearro.',

  'search_page.schema_title.parts': 'Dviračių dalys ir komponentai | Gearro',
  'search_page.schema_description.parts':
    'Apsipirkite aukštos kokybės dviračių dalių ir komponentų. Gearro rasite rėmų, pakabos, pavaros, perjungėjų, stabdžių ir dar daugiau.',
  'search_page.schema_title.parts.frame': 'Parduodami dviračių rėmai | Gearro',
  'search_page.schema_description.parts.frame':
    'Atraskite patvarius ir lengvus dviračių rėmus. Išsirinkite rėmą savo poreikiams iš Gearro.',
  'search_page.schema_title.parts.suspension': 'Dviračių amortizatoriai | Gearro',
  'search_page.schema_description.parts.suspension':
    'Pagerinkite važiavimą naudodami geriausius amortozatorius. Gearro raskite šakių ir amortizatorių.',
  'search_page.schema_title.parts.drivetrain': 'Dviračių pavaros | Gearro',
  'search_page.schema_description.parts.drivetrain':
    'Atnaujinkite savo dviratį patikimomis pavaromis. Gearro įsigykite grandinių, bėgių perjungiklių.',
  'search_page.schema_title.parts.brakes': 'Dviračių stabdžiai | Gearro',
  'search_page.schema_description.parts.brakes':
    'Užtikrinkite savo saugumą naudodami aukštos kokybės dviračio stabdžius. Nuo diskinių iki ratlankių stabdžių - viską, ko jums reikia, rasite Gearro.',
  'search_page.schema_title.parts.wheels': 'Dviračių ratai ir ratlankiai | Gearro',
  'search_page.schema_description.parts.wheels':
    'Sklandžiai riedėkite su mūsų siūlomais dviračių ratais ir ratlankiais. Atraskite patvarius variantus visoms kategorijoms Gearro.',
  'search_page.schema_title.parts.tyres_tubes': 'Dviračių padangos bet kokiam reljefui | Gearro',
  'search_page.schema_description.parts.tyres_tubes':
    'Gearro raskite dviračių padangas bet kokiam reljefui. Rinkitės iš plento, kalnų ir gravel padangų.',
  'search_page.schema_title.parts.cockpit': 'Dviračio vairas ir vairo komponentai | Gearro',
  'search_page.schema_description.parts.cockpit':
    'Įsigykite kokybiškų vairų, vairo iškišų, vairo juostų ir kitu dalių Gearro.',
  'search_page.schema_title.parts.seat': 'Dviračių sėdynės, balneliai, ir kitos dalys | Gearro',
  'search_page.schema_description.parts.seat':
    'Patirkite komfortą su mūsų dviračių sėdynių ir balnelių asortimentu Gearro.',

  'verify_email_page.title': 'Patvirtinkite el. paštą',
  'verify_email_page.verify_email_address': 'Patvirtinkite el. paštą',
  'verify_email_page.finish_account_setup':
    'Norėdami patvirtinti savo el. paštą, paspauskite patvirtinimo mygtuką, kurį atsiuntėme į {email}',
  'verify_email_page.loading_user_information': 'Kraunama informacija apie vartotoją',
  'verify_email_page.email_not_received': 'Nepasiekė laiškas?',
  'verify_email_page.resend_email': 'Persiųsti',
  'verify_email_page.verification_error': 'Kažkas nutiko... Prašome pabandyti vėliau.',

  'verify_phone_page.title': 'Patvirtinkite telefono numerį',
  'verify_phone_page.verify_phone': 'Patvirtinkite telefono numerį',
  'verify_phone_page.phone_number': 'Telefono numeris',
  'verify_phone_page.phone_number_placeholder': '+370',
  'verify_phone_page.phone_number_required': 'Įveskite telefono numerį',
  'verify_phone_page.phone_number_invalid': 'Neteisingas telefono numeris',
  'verify_phone_page.incorrect_number_format': 'Neteisingas telefono numeris, ar įvedėte šalies kodą?',
  'verify_phone_page.code': 'Kodas',
  'verify_phone_page.code_placeholder': '4 skaitmenų kodas iš SMS',
  'verify_phone_page.code_required': 'Įveskite kodą',
  'verify_phone_page.code_invalid': 'Neteisingas kodas',
  'verify_phone_page.phone_number_info': 'Įveskite savo telefono numerį. Tai padidins jūsų paskyros saugumą.',
  'verify_phone_page.code_info_line_1': 'Mes išsiuntėme SMS žinutę į {phone}.',
  'verify_phone_page.code_info_line_2': 'Įveskite 4 skaitmenų kodą žemiau.',
  'verify_phone_page.loading_user_information': 'Kraunama informacija apie vartotoją',
  'verify_phone_page.resend_code': 'Nepavyko? Persiųsti kodą',
  'verify_phone_page.generic_error': 'Kažkas nutiko... Prašome pabandyti vėliau.',
  'verify_phone_page.already_created_error': 'Šis numeris jau yra susietas su kitu vartotoju.',

  'PrivacyPolicyPage.schemaTitle': 'Privatumo politika | {siteTitle}',

  'PasswordResetPage.passwordChangedHeading': 'Slaptažodis pakeistas',
  'PasswordResetPage.passwordChangedHelpText': 'Dabar galite prisijungti su nauju slaptažodžiu.',
  'PasswordResetPage.loginButtonText': 'Prisijungti',
  'PasswordResetPage.mainHeading': 'Naujas slaptažodis',
  'PasswordResetPage.helpText': 'Įveskite naują slaptažodį žemiau.',
  'PasswordResetForm.passwordLabel': 'Naujas slaptažodis',
  'PasswordResetForm.passwordPlaceholder': 'Įveskite naują slaptažodį',
  'PasswordResetForm.passwordRequired': 'Įveskite naują slaptažodį',
  'PasswordResetForm.passwordTooShort': 'Slaptažodis turi būti bent {minLength} simbolių ilgio',
  'PasswordResetForm.passwordTooLong': 'Slaptažodis turi būti ne ilgesnis nei {maxLength} simbolių',
  'PasswordResetForm.submitButtonText': 'Pakeisti slaptažodį',

  'NotFoundPage.heading': 'Puslapis nerastas',
  'NotFoundPage.description': 'Atsiprašome, bet ieškomas puslapis nerastas.',
};
