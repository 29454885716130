import classNames from 'classnames';
import { array, arrayOf, bool, func, node, oneOfType, shape, string } from 'prop-types';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { Button } from '..';
import config from '../../config';
import { formatMoney } from '../../util/currency';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import css from './ListingOrderPanel.module.css';

const priceData = (price, intl) => {
  if (price && price.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  }
  if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

function ListingOrderPanel({
  rootClassName,
  className,
  listing,
  author,
  intl,
  onFetchTransactionLineItems,
  onBuyNow,
  buyLoading,
  onContactUser,
  isOwnListing,
  availableForPurchase,
}) {
  useEffect(() => {
    onFetchTransactionLineItems();
  }, []);

  const { price } = listing.attributes;
  const { formattedPrice, priceTitle } = priceData(price, intl);

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = config.listingManagementType === 'stock' && currentStock === 0;
  const shouldDisplayBuyNow = availableForPurchase && !isOwnListing;
  const shouldDisplayContact = !availableForPurchase && !isOwnListing;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.openOrderForm}>
        {isOwnListing && (
          <div className={css.priceContainer}>
            <div className={css.priceValue} title={priceTitle}>
              {formattedPrice}
            </div>
          </div>
        )}
        {shouldDisplayBuyNow && (
          <Button rootClassName={css.orderButton} onClick={onBuyNow} inProgress={buyLoading} disabled={isOutOfStock}>
            {intl.formatMessage({ id: 'listing_page.buy_now' })}
          </Button>
        )}
        {shouldDisplayContact && (
          <Button rootClassName={css.orderButton} onClick={() => onContactUser(author, listing)}>
            {intl.formatMessage({ id: 'listing_page.contact_seller' })}
          </Button>
        )}
      </div>
    </div>
  );
}

ListingOrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.lineItemUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingOrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.lineItemUnitType,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  onFetchTransactionLineItems: func.isRequired,
  onBuyNow: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(ListingOrderPanel);
