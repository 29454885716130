import { Divider, Stack, Typography } from '@mui/material';
import { bool, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { IconPaymentMethod, Logo } from '..';
import config from '../../config';
import { injectIntl, intlShape } from '../../util/reactIntl';
import css from './Footer.module.css';
import { FooterLinksSection } from './FooterLinksSection';
import { FooterSocialLinks } from './FooterSocialLinks';

function Footer({ intl, isAuthenticated }) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  return (
    <Stack
      maxWidth={1320}
      width={theme => `calc(100% - ${theme.spacing(4)})`}
      mx="auto"
      gap={3}
      //  px={{ xs: 2, md: 14 }}
      pt={{ xs: 6, md: 8 }}
      pb={4}
    >
      <Logo format="desktop" className={css.logo} />
      <Stack direction="row" gap={1}>
        {['apple', 'google', 'mastercard', 'visa'].map(paymentMethod => (
          <IconPaymentMethod key={paymentMethod} method={paymentMethod} />
        ))}
      </Stack>
      <Stack direction="row" flexWrap="wrap" justifyContent="center" gap={4}>
        <FooterLinksSection
          title={intl.formatMessage({ id: 'footer.product' })}
          items={[
            { name: intl.formatMessage({ id: 'footer.buy_bike' }), route: 'SearchPage' },
            { name: intl.formatMessage({ id: 'footer.sell_bike' }), route: 'NewListingPage' },
            isAuthenticatedOrJustHydrated
              ? null
              : { name: intl.formatMessage({ id: 'footer.business_join' }), route: 'BusinessLoginPage' },
          ].filter(Boolean)}
        />
        {/* <FooterLinksSection
          title={intl.formatMessage({ id: 'footer.company' })}
          items={[{ name: intl.formatMessage({ id: 'footer.about_us' }), route: 'AboutPage' }]}
        /> */}
        <FooterLinksSection
          title={intl.formatMessage({ id: 'footer.support' })}
          items={[
            { name: config.supportEmail, href: `mailto:${config.supportEmail}` },
            // { name: intl.formatMessage({ id: 'footer.help_center' }), route: 'FAQPage' },
          ]}
        />
        <FooterLinksSection
          title={intl.formatMessage({ id: 'footer.legal' })}
          items={[
            { name: intl.formatMessage({ id: 'footer.privacy_policy' }), route: 'PrivacyPolicyPage' },
            { name: intl.formatMessage({ id: 'footer.terms_conditions' }), route: 'TermsOfServicePage' },
          ]}
        />
      </Stack>
      <Divider sx={{ mt: 6, mb: 1 }} />
      <Stack
        gap={3}
        direction={{ xs: 'column', md: 'row-reverse' }}
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', md: 'center' }}
      >
        <FooterSocialLinks />
        <Typography variant="body2" color="textSecondary" fontSize={16}>
          {intl.formatMessage({ id: 'footer.content_rights' })}
        </Typography>
      </Stack>
    </Stack>
  );
}

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  isAuthenticated: bool.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;

  return {
    isAuthenticated,
  };
};

export default compose(injectIntl, connect(mapStateToProps))(Footer);
