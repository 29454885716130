import React from 'react';

import css from './TopBarWithClose.module.css';

import BackArrowIcon from '../../assets/icons/arrows/back.png';
import HeartOutlinedIcon from '../../assets/icons/hearts/outlined-24.png';
import HeartFilledIcon from '../../assets/icons/hearts/filled-24.png';

function TopBarWithClose(props) {
  const {
    onCloseClick,
    itemTitle,
    isLiked,
    addUserFavorite,
    removeUserFavorite,
    listingId,
    isOwnListing,
    currentUserId,
    listingType,
  } = props;

  const onLikeClick = e => {
    e.stopPropagation();
    e.preventDefault();

    isLiked ? removeUserFavorite(listingId) : addUserFavorite(listingId, listingType);
  };

  function LikeComponent() {
    return (
      <>
        {isLiked ? (
          <img className={css.likeIcon} onClick={e => onLikeClick(e)} src={HeartFilledIcon} />
        ) : (
          <img className={css.likeIcon} onClick={e => onLikeClick(e)} src={HeartOutlinedIcon} />
        )}
      </>
    );
  }

  return (
    <div className={css.root}>
      <img onClick={onCloseClick} className={css.closeIcon} src={BackArrowIcon} />
      <span className={css.itemTitle}>{itemTitle}</span>
      {currentUserId && !isOwnListing ? <LikeComponent /> : null}
    </div>
  );
}

export default TopBarWithClose;
