/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import { Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import Select from 'react-select';
import { compose } from 'redux';
import { NamedLink } from '../..';
import FavoriteIcon from '../../../assets/icons/hearts/outlined-24.png';
import InfoIcon from '../../../assets/icons/info-24.png';
import LtFlag from '../../../assets/icons/lt-flag.png';
import MessageIcon from '../../../assets/icons/message.png';
import PlFlag from '../../../assets/icons/pl-flag.png';
import PurchasesIcon from '../../../assets/icons/purchases.png';
import ArrowIcon from '../../../assets/icons/right-arrow.png';
import SalesIcon from '../../../assets/icons/sales.png';
import SearchIcon from '../../../assets/icons/search.svg';
import SettingIcon from '../../../assets/icons/setting.png';
import UkFlag from '../../../assets/icons/uk-flag.png';
import config from '../../../config';
import { LOCALES } from '../../../translations/locales';
import { ensureCurrentUser, userAbbreviatedName } from '../../../util/data';
import { propTypes } from '../../../util/types';
import Spacer, { HORIZONTALL_AXIS, LARGE_SPACER, MEDIUM_SPACER } from '../../Spacer/Spacer';
import css from './TopbarMobileMenu.module.css';

const CountrySwitcher = props => {
  const { intl, onLocaleChange, currentLocale, appCountry } = props;

  const ltOptions = [
    {
      value: LOCALES.LITHUANIAN,
      label: intl.formatMessage({ id: 'locales.lithuanian' }),
      icon: LtFlag,
    },
    {
      value: LOCALES.ENGLISH,
      label: intl.formatMessage({ id: 'locales.english' }),
      icon: UkFlag,
    },
  ];

  const plOptions = [
    {
      value: LOCALES.POLISH,
      label: intl.formatMessage({ id: 'locales.polish' }),
      icon: PlFlag,
    },
    {
      value: LOCALES.ENGLISH,
      label: intl.formatMessage({ id: 'locales.english' }),
      icon: UkFlag,
    },
  ];

  const options = appCountry === 'PL' ? plOptions : ltOptions;

  return (
    <div className={css.countrySwitch}>
      <Select
        options={options}
        value={options ? options.find(option => option.value === currentLocale) : ''}
        onChange={onLocaleChange}
        menuPlacement="auto"
        isSearchable={false}
        formatOptionLabel={option => (
          <div className={css.localeOption}>
            <img className={css.countryLogo} src={option.icon} alt={`language-${option.label}`} />
            <span className={css.countryName}>{option.label}</span>
          </div>
        )}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            height: '48px',
            fontSize: '16px',
            borderColor: '#D1D5DB',
            borderRadius: '4px',
            '&:hover': {
              borderColor: '#235641',
            },
          }),
        }}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: '#D1D5DB',
            primary: '#235641',
          },
        })}
      />
    </div>
  );
};

function TopbarMobileMenuComponent(props) {
  const {
    isAuthenticated,
    currentUser,
    notificationCount,
    onLogout,
    intl,
    history,
    onLocaleChange,
    currentLocale,
    onClose,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const onLogoutAction = e => {
    e.preventDefault();
    e.stopPropagation();
    onLogout();
    onClose();
  };

  const sellBikeLink = (
    <NamedLink name="NewListingPage" className={css.loginLink}>
      <span>{intl.formatMessage({ id: 'top_bar.sell' })}</span>
      <img className={css.arrowImage} src={ArrowIcon} />
    </NamedLink>
  );

  const buyBikeLink = (
    <NamedLink name="SearchPage" className={css.loginLink} state={{ prevPath: history.location.pathname }}>
      <img className={css.menuCellIcon} src={SearchIcon} />
      <Spacer axis={HORIZONTALL_AXIS} size={MEDIUM_SPACER} />
      <span>{intl.formatMessage({ id: 'top_bar.mobile_menu.all_categories' })}</span>
      <img className={css.arrowImage} src={ArrowIcon} />
    </NamedLink>
  );

  const aboutLink = (
    <NamedLink name="AboutPage" className={css.loginLink}>
      <img className={css.menuCellIcon} src={InfoIcon} />
      <Spacer axis={HORIZONTALL_AXIS} size={MEDIUM_SPACER} />
      <span>{intl.formatMessage({ id: 'top_bar.mobile_menu.about_page' })}</span>
      <img className={css.arrowImage} src={ArrowIcon} />
    </NamedLink>
  );

  const favoriteListingsLink = (
    <NamedLink
      name="FavoriteListingsPage"
      className={css.loginLink}
      params={{ id: currentUser?.id?.uuid || 1 }}
      state={{ prevPath: history.location.pathname }}
    >
      <img className={css.menuCellIcon} src={FavoriteIcon} />
      <Spacer axis={HORIZONTALL_AXIS} size={MEDIUM_SPACER} />
      <span>{intl.formatMessage({ id: 'top_bar.mobile_menu.favorite_listings' })}</span>
      <img className={css.arrowImage} src={ArrowIcon} />
    </NamedLink>
  );

  const topbarFooter = (
    <Stack mt={3} width="100%" direction="row" alignItems="center" justifyContent="center" gap={1} flexWrap="wrap">
      <NamedLink name="TermsOfServicePage">
        <Typography variant="body2" color="textSecondary" sx={{ textDecoration: 'underline' }}>
          {intl.formatMessage({ id: 'top_bar.mobile_menu.terms_of_service' })}
        </Typography>
      </NamedLink>
      <Typography variant="body2" color="textSecondary">
        •
      </Typography>
      <NamedLink name="PrivacyPolicyPage">
        <Typography variant="body2" color="textSecondary" sx={{ textDecoration: 'underline' }}>
          {intl.formatMessage({ id: 'top_bar.mobile_menu.privacy_policy' })}
        </Typography>
      </NamedLink>
    </Stack>
  );

  if (!isAuthenticated) {
    return (
      <div className={css.root}>
        <div className={css.content}>
          <Stack width="100%" gap={1} px={2} my={2}>
            <NamedLink name="LoginPage" className={css.actionButton}>
              {intl.formatMessage({ id: 'top_bar.sell' })}
            </NamedLink>
            <NamedLink name="LoginPage" className={css.actionSecondaryButton}>
              {intl.formatMessage({ id: 'top_bar.log_in' })}
            </NamedLink>
          </Stack>

          <Stack mt={5} gap={1} className={css.menuWrapper} onClick={onClose}>
            <Typography variant="h2" fontSize={18} fontWeight="bold">
              {intl.formatMessage({ id: 'top_bar.mobile_menu.explore' })}
            </Typography>
            <div className={css.menuCell} onClick={onClose}>
              {buyBikeLink}
            </div>
            {/* <div className={css.menuCell} onClick={onClose}>
              {aboutLink}
            </div> */}
          </Stack>
          <Spacer size={8} />
          <CountrySwitcher
            intl={intl}
            onLocaleChange={onLocaleChange}
            currentLocale={currentLocale}
            appCountry={config.countryCode}
          />
          {topbarFooter}
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <div className={css.notificationDot}>
        <span>{notificationCount > 9 ? '9+' : notificationCount}</span>
      </div>
    ) : null;

  const displayName = user.attributes.profile.displayName || user.attributes.profile.firstName;

  const inboxLink = (
    <NamedLink
      className={css.loginLink}
      name="InboxPage"
      params={{ lastLocation: history.location.pathname }}
      state={{ prevPath: history.location.pathname }}
    >
      <div className={css.inboxIconWrapper}>
        <img className={css.menuCellIcon} src={MessageIcon} />
      </div>
      <Spacer axis={HORIZONTALL_AXIS} size={MEDIUM_SPACER} />
      <span>{intl.formatMessage({ id: 'top_bar.inbox_label' })}</span>
      {notificationCountBadge}
      <img className={css.arrowImage} src={ArrowIcon} />
    </NamedLink>
  );

  const purchasesLink = (
    <NamedLink
      className={css.loginLink}
      name="PurchasesPage"
      params={{ lastLocation: history.location.pathname }}
      state={{ prevPath: history.location.pathname }}
    >
      <div className={css.inboxIconWrapper}>
        <img className={css.menuCellIcon} src={PurchasesIcon} />
      </div>
      <Spacer axis={HORIZONTALL_AXIS} size={MEDIUM_SPACER} />
      <span>{intl.formatMessage({ id: 'top_bar.purchases' })}</span>
      <img className={css.arrowImage} src={ArrowIcon} />
    </NamedLink>
  );

  const salesLink = (
    <NamedLink
      className={css.loginLink}
      name="SalesPage"
      params={{ lastLocation: history.location.pathname }}
      state={{ prevPath: history.location.pathname }}
    >
      <div className={css.inboxIconWrapper}>
        <img className={css.menuCellIcon} src={SalesIcon} />
      </div>
      <Spacer axis={HORIZONTALL_AXIS} size={MEDIUM_SPACER} />
      <span>{intl.formatMessage({ id: 'top_bar.sales' })}</span>
      <img className={css.arrowImage} src={ArrowIcon} />
    </NamedLink>
  );

  const walletLink = (
    <NamedLink className={css.loginLink} name="WalletPage" params={{ id: currentUser?.id?.uuid || 1 }}>
      <img className={css.menuCellIcon} src={PurchasesIcon} />
      <Spacer axis={HORIZONTALL_AXIS} size={MEDIUM_SPACER} />
      <span>{intl.formatMessage({ id: 'wallet.menu-title' })}</span>
      <img className={css.arrowImage} src={ArrowIcon} />
    </NamedLink>
  );

  const accountSettingsLink = (
    <NamedLink className={css.loginLink} name="AccountSettingsPage">
      <img className={css.menuCellIcon} src={SettingIcon} />
      <Spacer axis={HORIZONTALL_AXIS} size={MEDIUM_SPACER} />
      <span>{intl.formatMessage({ id: 'top_bar.account_settings_label' })}</span>
      <img className={css.arrowImage} src={ArrowIcon} />
    </NamedLink>
  );

  const abbreviatedName = userAbbreviatedName(currentUser, '');

  return (
    <div className={css.root}>
      <div onClick={onClose}>
        <NamedLink className={css.userCellWrapper} name="ProfilePage" params={{ id: currentUser?.id?.uuid || 1 }}>
          <div className={css.userProfileWrapper}>
            <span className={css.userName}>{abbreviatedName}</span>
          </div>
          <div className={css.userInfo}>
            <label className={css.displayName}>{displayName}</label>
            <span>{intl.formatMessage({ id: 'top_bar.mobile_menu.show_profile' })}</span>
          </div>
          <img className={css.arrowImage} src={ArrowIcon} />
        </NamedLink>
      </div>

      <div className={css.menuWrapper} onClick={onClose}>
        <div className={css.menuCell}>{inboxLink}</div>
        <div className={css.menuCell}>{purchasesLink}</div>
        <div className={css.menuCell}>{salesLink}</div>
        <div className={css.menuCell}>{walletLink}</div>
        <div className={css.menuCell}>{favoriteListingsLink}</div>
        <div className={css.menuCell}>{accountSettingsLink}</div>
      </div>
      <Spacer size={16} />
      <div className={css.menuWrapper} onClick={onClose}>
        {/* <div className={css.menuCellTitleWrapper}>
          <span className={css.menuCellTitle}>Gearro</span>
        </div> */}
        {/* <div className={css.menuCell}>{aboutLink}</div> */}
        {/* <Spacer size={8} /> */}
        <div className={css.menuCellLogOut} onClick={e => onLogoutAction(e)}>
          <span className={css.menuCellLogOutTitle}>{intl.formatMessage({ id: 'top_bar.log_out' })}</span>
        </div>
      </div>
      <CountrySwitcher
        intl={intl}
        onLocaleChange={onLocaleChange}
        currentLocale={currentLocale}
        appCountry={config.countryCode}
      />
      {topbarFooter}
      <Spacer size={LARGE_SPACER} />
    </div>
  );
}

TopbarMobileMenuComponent.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
};

const { bool, func, number, string } = PropTypes;

TopbarMobileMenuComponent.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

const TopbarMobileMenu = compose(withRouter)(TopbarMobileMenuComponent);

export default TopbarMobileMenu;
