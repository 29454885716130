import routeConfiguration from '../routing/routeConfiguration';
import * as log from '../util/log';
import { findRouteByRouteName } from '../util/routes';
import { clearAuthInfo, getAuthInfo, updateAuthInfoTokens } from './auth';

export function handleError(error) {
  try {
    log.error(
      {
        request: `${error.response?.config.method?.toUpperCase()} ${error.response?.config.url}`,
        error: error.response?.data,
        timestamp: new Date().toISOString(),
      },
      'backend-request-failed'
    );
  } catch (logError) {
    // eslint-disable-next-line no-console
    console.error({ logError, error });
  }
}

let retryInProgressRequest = null;

export async function handleRetry(error, apiInstance) {
  if (error.response?.data?.code === 1016) {
    redirectToPhoneVerificationPage();
    return Promise.reject(error);
  }

  if (error.response?.data?.code === 1010) {
    redirectToAcceptTermsOfServicePage();
    return Promise.reject(error);
  }

  const authInfo = getAuthInfo();
  if (error.response?.status !== 401 || !authInfo?.refresh_token) {
    return Promise.reject(error);
  }

  if (!retryInProgressRequest) {
    retryInProgressRequest = refresh(error, apiInstance).then(() => {
      retryInProgressRequest = null;
    });
  }

  try {
    await retryInProgressRequest;
    const req = error.config;
    apiInstance.options.onRequest?.(req);
    return apiInstance.client(req);
  } catch {
    retryInProgressRequest = null;
    return Promise.reject(error);
  }
}

async function refresh(error, apiInstance) {
  try {
    const authInfo = getAuthInfo();
    const result = await apiInstance.client.post('/auth/refresh', { refreshToken: authInfo.refresh_token });
    const { accessToken, refreshToken } = result.data;

    if (error.config && accessToken && refreshToken) {
      updateAuthInfoTokens(accessToken, refreshToken);
      // eslint-disable-next-line no-param-reassign
      error.config.headers.Authorization = `Bearer ${accessToken}`;
      return Promise.resolve();
    }

    return Promise.reject(error);
  } catch {
    clearAuthInfo();
    return Promise.reject(error);
  }
}

function redirectToPhoneVerificationPage() {
  if (typeof window !== 'undefined') {
    const verifyPhoneRoute = findRouteByRouteName('VerifyYourPhonePage', routeConfiguration());
    window.location.href = verifyPhoneRoute.path;
  }
}

function redirectToAcceptTermsOfServicePage() {
  if (typeof window !== 'undefined') {
    const acceptTermsOfServiceRoute = findRouteByRouteName('AcceptTermsOfServicePage', routeConfiguration());
    window.location.href = acceptTermsOfServiceRoute.path;
  }
}
